import { secondaryColor, sparketBlack, sparketDarkRed, XPOINT_NOT_FOUND, } from "utils/constants";
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import ErrorBadgeContent from "./badge-content/error";
import WaitingBadgeContent from "./badge-content/waiting";
import AllowedBadgeContent from "./badge-content/allowed";
import { GroupSettings } from "interfaces/group-settings";
import { useAppSelector } from "state/hooks";
import { getGroupState } from "state/slices/group-slice";
import styles from "./badge-styles.module.css";
import { CheckRequestStatus } from "@xpointtech/xpoint-js";

interface Props {
  status: CheckRequestStatus | typeof XPOINT_NOT_FOUND;
  showGeolocationModal: Dispatch<SetStateAction<boolean>>;
}

const THREE_SECONDS_FOR_CHECKMARK_ANIMATION = 3000;

const GeolocationStatus = ({ status, showGeolocationModal }: Props) => {
  const getContent = useCallback((status: CheckRequestStatus) => {
    switch (status) {
      case XPOINT_NOT_FOUND:
        return () => (
          <ErrorBadgeContent
            message="Xpoint not found"
            showGeolocationModal={showGeolocationModal}
          />
        );
      case CheckRequestStatus.WAITING:
        return () => <WaitingBadgeContent />;
      case CheckRequestStatus.ALLOWED:
        return () => <AllowedBadgeContent />;
      case CheckRequestStatus.DENIED:
        return () => (
          <ErrorBadgeContent
            message="Geolocation error"
            showGeolocationModal={showGeolocationModal}
          />
        );
      default:
        return () => <div></div>;
    }
  }, [showGeolocationModal]);

  const Content = useMemo(() => getContent(status), [getContent, status]);
  const [show, setShow] = useState(true);
  const groupSettings: GroupSettings = useAppSelector(getGroupState).settings;

  useEffect(() => {
    if (status === CheckRequestStatus.ALLOWED) {
      setTimeout(() => {
        setShow(false);
      }, THREE_SECONDS_FOR_CHECKMARK_ANIMATION);
    } else {
      setShow(true);
    }
  }, [status]);

  if (!show || !groupSettings.enable_geolocation) {
    return <div></div>;
  }

  return (
    <div className={styles.outerWrapper}>
      <div
        style={{
          backgroundColor: status === CheckRequestStatus.DENIED ? sparketDarkRed : sparketBlack,
          color: status === CheckRequestStatus.DENIED ? "white" : secondaryColor,
        }}
        className={styles.innerWrapper}
      >
        <Content />
      </div>
    </div>
  );
};

export default GeolocationStatus;
