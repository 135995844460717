import Header from "components/header";
import Headroom from "react-headroom";
import MyContests from "./my-contests";
import { useParams } from "react-router-dom";

const Wrapper = () => {
  const { id } = useParams<{ id: string }>();

  return (
    <>
      <Headroom>
        <Header />
      </Headroom>
      <div style={{ height: "calc(100vh - 66px)" }}>
        <MyContests contestEntryId={id} />
      </div>
    </>
  );
};

export default Wrapper;