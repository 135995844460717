import "rsuite/dist/rsuite.min.css";
import { useAppDispatch, useAppSelector } from "state/hooks";
import { fetchUserGroupMembershipStatus, getGroupState } from "state/slices/group-slice";
import { useEffect } from "react";
import { useAuth } from "hooks/auth";

const CheckGroupStatus = () => {
  const group = useAppSelector(getGroupState);
  const dispatch = useAppDispatch();
  const auth = useAuth();

  useEffect(() => {
    if (group.id && auth.signedIn) {
      dispatch(fetchUserGroupMembershipStatus(group.id));
    }
  }, [dispatch, group.id, auth.signedIn]);
};

export default CheckGroupStatus;
