import { useAuth } from "hooks/auth";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styles from "./header-styles.module.scss";
import useWallet from "hooks/useWallet";
import BlockyBoldText from "components/custom-texts/blocky-bold-text";
import getVerificationStatusIcon from "components/profile/identity-verification-info/getVerificationStatusIcon";
import { useAppSelector } from "state/hooks";
import { getUserState, getVerificationStatus } from "state/slices/user-slice";
import { DEFAULT_AVATAR_SRC } from "utils/constants";
import { getAppConfig } from "state/slices/app-config-slice";
import { getGroupState } from "state/slices/group-slice";
import { getIntegratedAppState } from "state/slices/integrated-app-slice";

const UserButton = () => {
  const auth = useAuth();
  const appConfig = useAppSelector(getAppConfig);
  const user = useAppSelector(getUserState);
  const integratedAppState = useAppSelector(getIntegratedAppState);
  const verificationStatus = useAppSelector(getVerificationStatus);
  const [imgSrc, setImgSrc] = useState(DEFAULT_AVATAR_SRC);
  const { formattedBalance } = useWallet();
  const group = useAppSelector(getGroupState);
  const showProfilePage = group.customization.profile_page;
  const showBalance = group.customization.balance;
  const balanceAlignStyle = showProfilePage ? {} : { justifyContent: "flex-end" };

  useEffect(() => {
    if (user.avatar) {
      setImgSrc(user.avatar);
    }
  }, [user.avatar]);

  const IdentityVerificationStatusIcon = getVerificationStatusIcon(verificationStatus);

  if (auth.signedIn) {
    return (
      <div className={styles.profilePicContainer} style={balanceAlignStyle}>
        {showProfilePage && (
          <div style={{ position: "relative", marginBottom: 3 }}>
            <Link
              to="/profile"
              style={{ pointerEvents: integratedAppState.isIntegratedApp ? "none" : "initial" }}
            >
              {
                appConfig.enableBattlePass ? (
                    <div className={styles.profileRow}>
                      <div className={styles.badgeColumn}>
                        {(user.badges || []).map((badge, index) => (
                          <img
                            key={index}
                            src={badge}
                            alt={`badge-${index}`}
                            className={styles.badgePic}
                          />
                        ))}
                      </div>
                      <img alt="Profile Picture" className={styles.profilePic} src={imgSrc}></img>
                    </div>
                  ) :
                  (
                    <img alt="" className={styles.profilePic} src={imgSrc}></img>
                  )
              }

            </Link>
            {
              IdentityVerificationStatusIcon &&
              React.cloneElement(IdentityVerificationStatusIcon, {
                width: 20,
                height: 20,
                style: {
                  position: "absolute",
                  right: -7,
                  bottom: -7,
                  border: "4px solid black",
                  borderRadius: 50,
                }
              })
            }
          </div>
        )}
        {showBalance && (
          <BlockyBoldText style={{ color: "white", fontSize: 12 }}>
            {formattedBalance}
          </BlockyBoldText>
        )}
      </div>
    );
  } else {
    return <div style={{ width: 25 }} />;
  }
};

export default UserButton;