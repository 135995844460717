import { Button } from "react-bootstrap";
import { RewardEnum, secondaryColor, sparketBlack, sparketLightGray } from "utils/constants";
import BlockyBoldText from "components/custom-texts/blocky-bold-text";
import AvatarDisplay from "./avatar-display/avatar-display";
import { useDispatch } from "react-redux";
import {
  getAvatarModalState,
  setSelectedAvatar,
  setSelectedBadges,
  setSelectedCategory,
  setShowModal
} from "state/slices/avatar-modal-slice";
import { useAppSelector } from "state/hooks";
import { getUserState } from "state/slices/user-slice";
import InitializeRewards from "components/avatar/initialize-rewards";
import React, { useState } from "react";
import ProfileModal from "components/avatar/modal/modal";
import EditAvatarDisplay from "components/avatar/modal/edit-avatar-display";
import { Order } from "interfaces/commerce/order/order";
import UpgradeProfileModalContent from "components/avatar/modal/upgrade-profile/upgrade-profile-modal-content";
import Username from "components/profile/Username";

interface AvatarProps {
  orders: Order[];
}

const Avatar = ({ orders }: AvatarProps) => {
  const dispatch = useDispatch();
  const userState = useAppSelector(getUserState);
  const avatarModalState = useAppSelector(getAvatarModalState);

  const handleEditAvatarButtonClick = () => {
    dispatch(setSelectedAvatar(userState.avatar));
    dispatch(setSelectedBadges(userState.badges));
    dispatch(setShowModal(true));
  };

  const [showUpgradeProfileModal, setShowUpgradeProfileModal] = useState(false);
  const handleUpgradeProfileButtonClick = () => {
    setShowUpgradeProfileModal(true);
  };

  InitializeRewards();

  const editAvatarModalRef = React.useRef<HTMLDivElement | null>(null);

  return (
    <div>
      <Username />

      <AvatarDisplay />
      <br></br>
      <Button
        style={{
          backgroundColor: sparketBlack,
          color: sparketLightGray,
          border: `1px solid ${secondaryColor}`,
          borderRadius: "unset",
          height: 40,
          width: 150,
        }}
        onClick={handleEditAvatarButtonClick}
      >
        <BlockyBoldText>Edit Avatar</BlockyBoldText>
      </Button>

      <Button
        style={{
          backgroundColor: secondaryColor,
          color: sparketBlack,
          border: "none",
          borderRadius: "unset",
          height: 40,
          width: 150,
        }}
        onClick={handleUpgradeProfileButtonClick}
      >
        <BlockyBoldText>Upgrade Profile</BlockyBoldText>
      </Button>

      <ProfileModal show={avatarModalState.showModal}
                    onClose={() => {
                      dispatch(setSelectedCategory(RewardEnum.AVATAR));
                      dispatch(setShowModal(false));
                    }}
                    title={"Edit Avatar"}
                    bodyRef={editAvatarModalRef}>
        <EditAvatarDisplay containerRef={editAvatarModalRef} />
      </ProfileModal>

      <ProfileModal show={showUpgradeProfileModal}
                    onClose={() => setShowUpgradeProfileModal(false)}
                    title={"Upgrade Profile"}>
        <UpgradeProfileModalContent orders={orders} />
      </ProfileModal>

    </div>

  );
};

export default Avatar;