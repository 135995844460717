import { Button } from "react-bootstrap";
import { secondaryColor, sparketBlack } from "utils/constants";
import BlockyBoldText from "components/custom-texts/blocky-bold-text";
import React, { useState } from "react";
import { InovioProduct, Product, ProductMeta } from "interfaces/commerce/product";
import { postRequest } from "utils/httpClient";
import { CreateOrderRequest } from "interfaces/commerce/order/create-order-request";
import { Order } from "interfaces/commerce/order/order";
import decodeBase64 from "utils/base-64-decoder";
import { toast } from "react-toastify";

interface InovioPurchaseButtonProps {
  existingOrder?: Order;
  product?: Product;
}

const InovioPurchaseButton: React.FC<InovioPurchaseButtonProps> = ({ existingOrder, product }) => {

  const [redirecting, setRedirecting] = useState(false);
  const [creatingOrder, setCreatingOrder] = useState(false);


  const buildInovioHostedPaymentPageUrl = (inovioProduct: InovioProduct,
                                           successRedirectUrl: string,
                                           orderId: string) => {

    if (!inovioProduct.client_id || !inovioProduct.site_id || !inovioProduct.product_id || !inovioProduct.merchant_account_id) {
      throw new Error("inovio product data is incomplete");
    }

    return `https://api.inoviopay.com/paymentpage/load/${inovioProduct.client_id}/${inovioProduct.site_id}/${inovioProduct.product_id}` +
      `?merch_ac_id=${inovioProduct.merchant_account_id}` +
      `&PG_REDIR=1` +
      `&PG_SUCC_URL=${successRedirectUrl}` +
      `&XTL_ORDER_ID=${orderId}` +
      "&PGCSS=https://www.sparket.app/inovio/payment-page-styles.css";
  };

  const navigateToInovioHostedPaymentPage = async () => {
    let order: Order;
    if (existingOrder) {
      order = existingOrder;
    } else if (product) {
      setCreatingOrder(true);
      try {
        order = await postRequest("/v2/order",
          { body: { productId: product.id } as CreateOrderRequest, }) as Order;
      } catch (e) {
        console.log("failed to create order");
        toast.error(`Something went wrong.`);
        return;
      } finally {
        setCreatingOrder(false);
      }

      order.product.meta = decodeBase64<ProductMeta>(order.product.meta);

    } else {
      toast.error(`Something went wrong.`);
      console.error("Neither of active order or product properties is provider");
      return;
    }

    const inovioProduct = (order.product.meta as ProductMeta).inovio_product;

    if (!inovioProduct) {
      toast.error(`Something went wrong.`);
      console.error("Inovio product metadata is missing for the selected product.");
      return;
    }

    const currentPageUrl = window.location.href;

    setRedirecting(true);

    try {
      window.location.href = buildInovioHostedPaymentPageUrl(inovioProduct, currentPageUrl, order.id);
    } catch (e) {
      toast.error(`Something went wrong.`);
      console.error("failed to build inovio hosted payment page url", e);
    }
  };

  return <div className={"d-flex flex-column justify-content-center align-items-center"}>
    <Button
      variant="primary"
      disabled={(!existingOrder && !product) || creatingOrder || redirecting}
      className="mt-2"
      onClick={navigateToInovioHostedPaymentPage}
      style={{
        backgroundColor: secondaryColor,
        color: sparketBlack,
        border: "none",
        borderRadius: "unset",
        height: 40,
        width: 250,
      }}
    >
      <BlockyBoldText>{existingOrder ? "Complete Payment" : "Purchase"}</BlockyBoldText>
    </Button>

    {redirecting &&
      <BlockyBoldText className={"text-center m-2"} style={{ color: "white" }}>
        Navigating you to the payment page...
      </BlockyBoldText>
    }

  </div>;
};

export default InovioPurchaseButton;