import { InfoCircleFill } from "react-bootstrap-icons";
import { OverlayTrigger, Popover } from "react-bootstrap";
import BlockyMediumText from "components/custom-texts/blocky-medium-text";
import { useAppSelector } from "state/hooks";
import { getGroupState } from "state/slices/group-slice";

const PendingIdentityVerificationTooltip = () => {
  const group = useAppSelector(getGroupState);
  return (
    <OverlayTrigger
      placement="bottom"
      overlay={
        <Popover placement="bottom" id="pending-identity-verificaiton-tooltip">
          <Popover.Body>
            <BlockyMediumText>
              {group.name} requires identity verification to join. You can view
              your verification status on the profile page.
            </BlockyMediumText>{" "}
          </Popover.Body>
        </Popover>
      }
    >
      <InfoCircleFill />
    </OverlayTrigger>
  );
};

export default PendingIdentityVerificationTooltip;
