import React from "react";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import { ProvideAuth } from "hooks/auth";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "state/store";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ScrollToTop from "components/scroll-to-top";
import { createRoot } from "react-dom/client";
import { Helmet, HelmetProvider } from "react-helmet-async";

const ToastContainerWithStyles = () => {
  return (
    <ToastContainer
      position="top-right"
      autoClose={7000}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      pauseOnFocusLoss
      pauseOnHover
      toastStyle={{
        background: "#1c2a36",
        color: "white",
        opacity: 1,
        border: `.5px solid var(--secondary-color}`,
      }}
    />
  );
};


if (
  process.env.REACT_APP_USE_LOCAL === "true" &&
  process.env.NODE_ENV !== "production"
) {
  // setupServer(true);
}
const container = document.getElementById("root");

const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <HelmetProvider>
      <Provider store={store}>
        <BrowserRouter>
          <ProvideAuth>
            <Helmet>
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1, maximum-scale=1,user-scalable=0"
              />
            </Helmet>
            <ScrollToTop />
            <App />
            <ToastContainerWithStyles />
          </ProvideAuth>
        </BrowserRouter>
      </Provider>
    </HelmetProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
