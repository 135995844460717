import Header from "components/header";
import Headroom from "react-headroom";
import MyBets from "./my-bets";

const Wrapper = () => {
  return (
    <>
      <Headroom>
        <Header />
      </Headroom>
      <MyBets />
    </>
  );
};

export default Wrapper;