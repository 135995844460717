import { Drawer } from "rsuite";
import "./drawer-styles.css";
import { ReactComponent as SparketLogo } from "icons/sparket-logo.svg";
import NavigationLinks from "./navigation-links";
import { useAppDispatch, useAppSelector } from "state/hooks";
import { close, getDrawerState } from "state/slices/drawer-slice";
import Footer from "./footer-content";
import AdBanner from "components/ad-banner/AddBanner";
import "rsuite/dist/rsuite.min.css";

const SparketDrawer = () => {
  const isOpen = useAppSelector(getDrawerState);
  const dispatch = useAppDispatch();

  return (
    <Drawer
      placement="left"
      backdrop={true}
      size="230px"
      open={isOpen}
      onClose={() => dispatch(close())}
      keyboard
    >
      <Drawer.Header
        style={{
          marginLeft: 20,
          marginRight: 20,
          marginTop: 20,
          padding: 0,
          paddingRight: 20,
          border: "none",
          position: "relative"
        }}>
        <Drawer.Title>
          <SparketLogo width={40} height={40} />
        </Drawer.Title>
      </Drawer.Header>

      <Drawer.Body style={{ padding: 0, marginTop: 30, position: "relative" }}>
        <div style={{ marginLeft: 10 }}>
          <NavigationLinks />
          <AdBanner styles={{ marginRight: 10 }} />
        </div>
        <div
          style={{
            position: "absolute",
            bottom: 30,
            marginRight: 20,
            marginLeft: 10,
            marginBottom: 10
          }}
        ><Footer /></div>
      </Drawer.Body>
    </Drawer>
  );
};
export default SparketDrawer;
