import { useAuth } from "hooks/auth";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useAppDispatch } from "state/hooks";
import { setGroup, setLoading } from "state/slices/group-slice";
import { getGroupBySlugPath } from "utils/backend-path-builders";
import { CURRENT_GROUP_ID } from "utils/constants";
import { getRequest } from "utils/httpClient";
import * as storage from "utils/local-storage";
import { useNavigate } from "react-router-dom";

const SetGroupStateFromSlug = (groupSlugFromUri: string | undefined) => {
  const [groupSlug, setGroupSlug] = useState(groupSlugFromUri);
  const dispatch = useAppDispatch();
  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (auth.signedIn || auth.pending) {
      return;
    }

    const localStorageGroupId = storage.local.getItem(CURRENT_GROUP_ID);

    if (!groupSlug && !groupSlugFromUri && !localStorageGroupId) {
      setGroupSlug(process.env.REACT_APP_DEFAULT_URI || "");
    }
  }, [groupSlug, auth, groupSlugFromUri]);

  useEffect(() => {
    if (groupSlug) {
      const path = getGroupBySlugPath(groupSlug);
      getRequest(path, { skipIntegrationApi: true })
        .then((data) => {
          if (data) {
            dispatch(setGroup(data));
          } else {
            throw `No group found for ${groupSlug}`;
          }
        })
        .catch(() => {
          toast.error(`Something went wrong. Group ${groupSlug} not found.`);
          navigate("/");
        })
        .finally(() => {
          dispatch(setLoading(false))
        });
    }
  }, [dispatch, navigate, groupSlug]);
};

export default SetGroupStateFromSlug;
