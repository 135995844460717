import BlockyHeavyText from "components/custom-texts/blocky-heavy-text";
import { formatAsCurrency } from "utils/formatter-utils/currency-formatter";
import { LeaderboardEntry } from "interfaces/leaderboard/leaderboard-entry";
import { DEFAULT_AVATAR_SRC } from "utils/constants";
import styles from "./styles.module.css";
import { useEffect, useState } from "react";

interface Props {
  user: LeaderboardEntry;
  style?: object;
  classNames: any;
  isMobile: boolean;
}

const UserRow = ({ user, style = {}, isMobile }: Props) => {
  const { rank, avatar, badges, total_earnings, user_id } = user;
  const [userName, setUserName] = useState(user.user_name);

  const avatarSrc = avatar === "" ? DEFAULT_AVATAR_SRC : avatar;

  const badgeGridClass = isMobile ? styles.badgeGridMobile : styles.badgeGridFull;
  const badgeDisplayClass = isMobile ? styles.badgeDisplayMobile : styles.badgeDisplayFull;

  useEffect(() => {
    if (userName.length > 15 && isMobile) {
      setUserName(userName.substring(0, 12) + "...");
    }

    if (!isMobile) {
      setUserName(user.user_name);
    }
  }, [userName, user.user_name, isMobile]);

  return (
    <tr style={style} key={user_id}>
      <td>
        <div style={{ textAlign: "right", display: "flex", alignItems: "center" }}>
          <BlockyHeavyText
            style={{
              marginRight: "5px",
              ...style
            }}
          >
            {rank}
          </BlockyHeavyText>
          <img
            src={avatarSrc}
            alt="sparket-logo"
            style={{ marginLeft: "auto", marginTop: "-9px", marginBottom: "-9px" }}
            width="38"
            height="38"
          />
        </div>
      </td>
      <td>
        <div style={{ display: "flex", alignItems: "center" }}>
          <BlockyHeavyText style={style}>{userName}</BlockyHeavyText>
          <div className={badgeGridClass}>
            {badges.map((badge, index) => {
              return (
                <img
                  key={index}
                  src={badge}
                  alt="badge"
                  className={badgeDisplayClass}
                />
              );
            })}
          </div>
        </div>
      </td>
      <td>
        <BlockyHeavyText style={style}>
          {formatAsCurrency(total_earnings)}
        </BlockyHeavyText>
      </td>
    </tr>
  );
};
export default UserRow;
