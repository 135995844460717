import BlockyBoldText from "components/custom-texts/blocky-bold-text";
import { useAuth } from "hooks/auth";
import { useState } from "react";
import { Button } from "react-bootstrap";
import { useAppSelector } from "state/hooks";
import { isJoined } from "state/slices/contests-slice";
import { getGroupState } from "state/slices/group-slice";
import { sparketGold } from "utils/constants";
import EnterContestModal from "./modal";
import { useNavigate } from "react-router-dom";
import { UserGroupMembershipStatus } from "utils/userGroupMembershipStatus.ts";

const Container = ({ contest }: any) => {
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const {
    status,
    settings: { secondary_color },
  } = useAppSelector(getGroupState);
  const user = useAuth();
  const joined = useAppSelector((s) => isJoined(s, contest.id));

  const joinedGroup = status === UserGroupMembershipStatus.APPROVED;
  const signedIn = user.signedIn;
  const disableButton = !joinedGroup || !signedIn;

  const buttonStyles = joined
    ? {
      backGround: "white",
      borderColor: "black",
    }
    : {
      background: secondary_color || sparketGold,
      color: "black",
      border: secondary_color || sparketGold,
    };

  return (
    <>
      <>
        <Button
          size="sm"
          variant={joined ? "outline" : ""}
          style={buttonStyles}
          disabled={disableButton}
          onClick={() => {
            if (!joined) {
              setShowModal(true);
            } else {
              navigate("/mycontests/" + contest.id);
            }
          }}
        >
          <>
            {joined ? (
              <BlockyBoldText>View Contest</BlockyBoldText>
            ) : (
              <BlockyBoldText>Enter Contest</BlockyBoldText>
            )}
          </>
        </Button>
      </>

      <EnterContestModal
        setShowModal={setShowModal}
        showModal={showModal}
        contest={contest}
      />
    </>
  );
};

export default Container;
