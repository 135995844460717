import BlockyHeavyText from "components/custom-texts/blocky-heavy-text";
import BlockyMediumText from "components/custom-texts/blocky-medium-text";
import Header from "components/header";
import Headroom from "react-headroom";
import { Link } from "react-router-dom";
import styles from "./styles.module.css";
import ExclusionsAndLimitationsForms from "./exclusions-and-limitations-forms";

const ResponsibleGamingContainer = () => {
  return (
    <>
      <Headroom>
        <Header />
      </Headroom>
      <div className={styles.pageWrapper}>
        <BlockyHeavyText className={styles.title}>
          Responsible Gaming
        </BlockyHeavyText>
        <BlockyMediumText>
          This page allows you to set limits or exclude yourself from real money
          wagering for a period of time. You can find more resources on
          Responsible Gaming and what to do if you have a problem on our{" "}
          <Link to="/help">help page</Link>. Limit increases will be reviewed
          and may have a cooling off period of 24 hours. If you already have an
          exclusion in place, you will need to contact us to remove or modify
          it.
        </BlockyMediumText>
        <ExclusionsAndLimitationsForms />
      </div>
    </>
  );
};

export default ResponsibleGamingContainer;
