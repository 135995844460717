import BlockyHeavyText from "components/custom-texts/blocky-heavy-text";
import BlockyLightText from "components/custom-texts/blocky-light-text";
import { useAuth } from "hooks/auth";
import { Button } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "state/hooks";
import { getGroupState } from "state/slices/group-slice";
import { showSignIn, showSignUp } from "state/slices/login-modals-slice";
import { secondaryColor, sparketBlack } from "utils/constants";

const LoginButtons = () => {
  const group = useAppSelector(getGroupState);
  const showLogin = group.customization.sign_in_up;
  const auth = useAuth();
  const dispatch = useAppDispatch();

  if (auth.signedIn || !showLogin) {
    return null;
  }

  return (
    <div
      style={{
        backgroundColor: sparketBlack,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: 10,
        color: "white",
        fontSize: 13,
      }}
    >
      <div style={{ display: "flex", alignItems: "center", minWidth: 165 }}>
        <Button
          style={{
            backgroundColor: secondaryColor,
            border: "none",
            color: "black",
          }}
          size="sm"
          onClick={() => dispatch(showSignIn())}
        >
          <BlockyHeavyText>Sign In</BlockyHeavyText>
        </Button>
        &nbsp;&nbsp;&nbsp; <BlockyLightText>or</BlockyLightText>{" "}
        &nbsp;&nbsp;&nbsp;
        <Button
          style={{
            backgroundColor: sparketBlack,
            borderColor: secondaryColor,
            color: secondaryColor,
          }}
          size="sm"
          onClick={() => dispatch(showSignUp())}
        >
          <BlockyHeavyText>Sign Up</BlockyHeavyText>
        </Button>
      </div>
      &nbsp;&nbsp;&nbsp;
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {group.name && (
          <BlockyLightText>and join {group.name}&nbsp;</BlockyLightText>
        )}{""}
        <BlockyLightText>before making your first pick</BlockyLightText>
      </div>
    </div>
  );
};

export default LoginButtons;
