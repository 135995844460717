import { Pool } from "interfaces/pool";
import React from "react";
import PoolItem from "components/pool-item/pool-item";
import styles from "assets/shared-stylesheets/sparket-list-item.module.css";
import BlockyHeavyText from "components/custom-texts/blocky-heavy-text";

interface Props {
  pools: Pool[];
  contestEntryId?: string;
}

const PoolList = ({pools, contestEntryId}: Props) => {

  return (
    <>
      {pools.length > 0 ? (
        <>
          {pools.map((pool) => {

            const link = contestEntryId ? `/pools/${pool.id}?entryId=${contestEntryId}` : `/pools/${pool.id}`;

            return (
              <PoolItem
                key={pool.id}
                styles={styles}
                pool={pool}
                link={link}
                showCountDownClock={true}
              />
            );
          })}
        </>
      ) : (
        <BlockyHeavyText
          customStyles={{
            display: "flex",
            justifyContent: "center",
            color: "white",
          }}
        >
          No pools are currently open. Please check back later.
        </BlockyHeavyText>
      )}
    </>
  )


}

export default PoolList;