import React, { CSSProperties } from "react";
import { Order } from "interfaces/commerce/order/order";
import { Badge, Card, Col, Container, Row } from "react-bootstrap";
import { useAppSelector } from "state/hooks";
import { getGroupStyles } from "state/slices/group-slice";
import InovioPurchaseButton from "components/payment-provider/inovio/inovio-purchase-button";

interface OrderItemProps {
  order: Order;
}

const OrderItem: React.FC<OrderItemProps> = ({ order }) => {
  const primaryColor = useAppSelector(getGroupStyles).primary_color;

  const badgeStyle = {
    width: "100%",
    maxWidth: "150px",
    color: "black",
    textAlign: "center",
    padding: "0.3em",
    whiteSpace: "normal",
    wordWrap: "break-word",
  } as CSSProperties;

  const getStatusBadge = (status: string) => {
    switch (status) {
      case "NEW":
        return <Badge bg={"info"} style={badgeStyle}>Payment Required</Badge>;
      case "AWAITING_PAYMENT_CONFIRMATION":
        return <Badge bg={"info"} style={badgeStyle}>Processing</Badge>;
      case "COMPLETED":
        return <Badge bg={"success"} style={badgeStyle}>Completed</Badge>;
      case "EXPIRED":
        return <Badge bg={"light"} style={badgeStyle}>Expired</Badge>;
      default:
        return null;
    }
  };

  const statusBadge = getStatusBadge(order.status);

  return (
    <Card
      style={{
        backgroundColor: "#2c2c2c",
        color: "#f5f5f5",
        width: "90%",
        borderRadius: "unset",
        border: "2px solid " + primaryColor,
        padding: "16px",
        marginBottom: "16px",
        position: "relative"
      }}
    >
      <Card.Body>
        <Card.Title style={{ borderBottom: "1px solid " + primaryColor }}>
          <Container>
            <Row className={"mb-1 position-relative"}>
              <Col className={"col-7 text-center"}>{order.product.name}</Col>
              {statusBadge && (
                <Col className={"col-5 d-flex justify-content-end align-items-center"} style={{ right: 10 }}>
                  {statusBadge}
                </Col>
              )}
            </Row>
          </Container>
        </Card.Title>
        <Card.Subtitle
          className="mb-2 text-muted fw-bold"
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textAlign: "center",
            textOverflow: "ellipsis"
          }}
        >
          {order.id}
        </Card.Subtitle>
        <Card.Body>
          <Container>
            <Row>
              {new Date(order.created_at).toLocaleString()}
            </Row>
            <Row>
              ${order.price.toFixed(2)}
            </Row>
            <Row>
              {order.card_last4 && <p className="p-0">Paid with : ****************{order.card_last4}</p>}
            </Row>
          </Container>
        </Card.Body>

        {order.status === "NEW" && <InovioPurchaseButton existingOrder={order} />}
      </Card.Body>
    </Card>
  );
};

export default OrderItem;
