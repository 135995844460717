import Header from "components/header";
import LoadingSpinner from "components/loading-spinner.tsx";
import PoolSummary from "components/pool-details/pool-summary";
import { Entry } from "interfaces/entry";
import { PoolDetailsResponse } from "interfaces/pool-details-response";
import { useEffect, useState } from "react";
import Headroom from "react-headroom";
import { getRequest } from "utils/httpClient";
import Representer from "./representer";
import { Alert } from "react-bootstrap";
import { useAppSelector } from "state/hooks";
import { getGroupState } from "state/slices/group-slice";
import { getConfirmedContestantPath, getPoolDetailsPath, } from "utils/backend-path-builders";
import { Navigate, useParams } from "react-router-dom";

const ConfirmResultsPoolDetails = () => {
  const { id } = useParams<{ id: string }>();
  const [loadingPool, setLoadingPool] = useState(true);
  const [entries, setEntries] = useState<Entry[]>([]);
  const [pool, setPool] = useState<PoolDetailsResponse>(
    {} as PoolDetailsResponse
  );
  const group = useAppSelector(getGroupState);
  const [confirmedContestantId, setConfirmedContestantId] = useState("");

  useEffect(() => {
    if (pool.id) {
      const path = getConfirmedContestantPath(pool.id);
      getRequest(path).then((data) => {
        if (data && data.contestant_id) {
          setConfirmedContestantId(data.contestant_id);
        }
      });
    }
  }, [pool]);

  useEffect(() => {
    if (!id) {
      return;
    }
    if (!group.id) {
      return;
    }

    const path = getPoolDetailsPath(group.id, id);
    getRequest(path).then((data) => {
      setEntries(data.entries);
      setPool(data);
      setLoadingPool(false);
    });
  }, [id, group.id]);

  if (!id) {
    return <Navigate to="/error" />;
  }

  if (loadingPool) {
    return <LoadingSpinner />;
  }

  const explanationText =
    confirmedContestantId === ""
      ? `Select winning contestant of ${pool.name} to confirm.`
      : "You have confirmed a winner for this pool.";

  return (
    <>
      <Headroom>
        <Header />
        <PoolSummary pool={pool} backUrl="/confirm-results" betType="win" />
        <Alert variant="warning">{explanationText}</Alert>
      </Headroom>

      <Representer
        confirmedContestantId={confirmedContestantId}
        setConfirmedContestantId={setConfirmedContestantId}
        entries={entries}
        poolId={pool.id}
      />
    </>
  );
};

export default ConfirmResultsPoolDetails;
