import styles from "./styles.module.scss";
import BlockyHeavyText from "components/custom-texts/blocky-heavy-text";
import BlockyBoldText from "components/custom-texts/blocky-bold-text";
import { useAppSelector } from "state/hooks";
import { getSlugPath, getSlugUrl } from "state/slices/group-slice";
import { Link } from "react-router-dom";
import QRCode from "react-qr-code";

const CallToActionFooter = () => {
  const slugUrl = useAppSelector(getSlugUrl);
  const slugPath = useAppSelector(getSlugPath);

  return (
    <div className={styles.container}>
      <div className={styles.contents}>
        <BlockyBoldText className={styles.getInOnAction}>
          Get in on the action at &nbsp;
        </BlockyBoldText>
        <BlockyHeavyText className={styles.getInOnAction}>
          <Link to={slugPath}>{slugUrl}</Link>
        </BlockyHeavyText>
        <QRCode value={slugUrl} size={100} />
      </div>
    </div>
  );
};

export default CallToActionFooter;
