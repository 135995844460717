import { useEffect } from "react";
import { useAuth } from "hooks/auth";
import { Mixpanel } from "hooks/mixPanel";
import Bowser, { Parser } from "bowser";

const SetupMixpanel = () => {
  const auth = useAuth();
  useEffect(() => {
    if (auth.user?.signInUserSession?.idToken?.payload?.email) {
      Mixpanel.identify(auth.user.signInUserSession.idToken.payload.email);
    }
    const parser: Parser.Parser = Bowser.getParser(window.navigator.userAgent);
    Mixpanel.track("Home page rendered", {
      browserName: parser.getBrowserName(),
      browserVersion: parser.getBrowserVersion(),
      platformType: parser.getPlatformType(),
      platformVendor: parser.getPlatform().vendor,
      isInstagram: parser.getUA().includes("Instagram"),
      isFacebook: parser.getUA().includes("FB_IAB"),
      userAgentString: parser.getUA(),
    });
  }, [auth.user?.signInUserSession?.idToken?.payload?.email]);
};

export default SetupMixpanel;
