import BlockyHeavyText from "components/custom-texts/blocky-heavy-text";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import { secondaryColor, sparketBlack } from "utils/constants";

interface ViewPoolLinkProps {
  poolId: string;
}

const ViewPoolLink = (props: ViewPoolLinkProps) => {
  const navigate = useNavigate();
  return (
    <Button
      style={{
        padding: 10,
        borderRadius: 2,
        backgroundColor: secondaryColor,
        border: "none",
        color: sparketBlack,
      }}
      onClick={() => navigate(`/pools/${props.poolId}`)}
    >
      <BlockyHeavyText style={{ color: "black" }}>{"View Pool >"}</BlockyHeavyText>
    </Button>
  );
};

export default ViewPoolLink;
