import { useAppSelector } from "state/hooks";
import { getAvatarModalState, setSelectedAvatar, setSelectedBadges } from "state/slices/avatar-modal-slice";
import React, { useState } from "react";
import { BADGE_GRID_DIMENSIONS, Reward, RewardEnum } from "utils/constants";
import { Image, OverlayTrigger, Tooltip } from "react-bootstrap";
import styles from "./styles.module.css";
import { useDispatch } from "react-redux";
import lockLogo from "icons/lock.svg";
import BlockyLightText from "components/custom-texts/blocky-light-text";

const SelectionSection: React.FC = () => {
  const dispatch = useDispatch();
  const avatarModalState = useAppSelector(getAvatarModalState);
  const [selection, setSelection] = useState<RewardEnum>(avatarModalState.selectedCategory);
  const rewards: Reward[] = avatarModalState.rewards

  React.useEffect(() => {
    setSelection(avatarModalState.selectedCategory);
  }, [avatarModalState.selectedCategory]);

  const handleRewardSelect = (reward: Reward) => {
    const unlocked: boolean = reward.unlocked;
    const url: string = reward.url;
    if (unlocked) {
      switch(reward.type) {
        case RewardEnum.AVATAR:
          dispatch(setSelectedAvatar(url));
          break;
        case RewardEnum.BADGE:
          let badges: string[] = [...avatarModalState.selectedBadges];
          if (badges.includes(url)) {
            badges = badges.filter(badge => badge !== url);
            dispatch(setSelectedBadges(badges));
          } else if (badges.length < 4) {
            badges.push(url);
            dispatch(setSelectedBadges(badges));
          } else {
            alert("You can only select up to 4 badges.");
          }
      }
    }
  }

  const selectedRewards = rewards.filter(reward => reward.type === selection)

  return (
        <div
          style={{
            gridTemplateColumns: `repeat(${BADGE_GRID_DIMENSIONS.COLUMNS}, 1fr)`,
            gridTemplateRows: `repeat(${BADGE_GRID_DIMENSIONS.ROWS}, 1fr)`}}
          className={styles.imageGrid}>
          {selectedRewards.map((reward, index) => (
            <OverlayTrigger
              placement="top"
              overlay={lockTooltip(reward.description, reward.id)}
              delay={{ show: 250, hide: 400 }}
            >
              <div key={index} className={styles.imageContainer}>
                <Image
                  src={reward.url}
                  className={styles.image}
                  onClick={() => handleRewardSelect(reward)}
                />

                {!reward.unlocked && (
                  <div className={styles.lockOverlay}>
                    <Image
                      src={lockLogo}
                      className={styles.lockIcon}
                    />
                  </div>
                )}

              </div>
            </OverlayTrigger>
          ))}
      </div>
  );
}

const lockTooltip = (description: string, id: string) => {
  return (
    <Tooltip id={id}>
      <BlockyLightText>{description}</BlockyLightText>
    </Tooltip>
  );
}

export default SelectionSection;