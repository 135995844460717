import styles from "./styles.module.scss";

const LoadingBetForm = () => {
  return (
    <div
      style={{
        position: "fixed",
        bottom: 0,
        width: "-webkit-fill-available",
        background: "black",
        color: "white",
        padding: 10,
        fontSize: 14,
      }}
    >
      {/* top panel */}
      <div style={{ width: "100%", display: "flex", height: 10 }}>
        {/* left panel */}
        <div
          style={{ flex: 0.5, height: 22, marginRight: 10 }}
          className={styles.animatedBackground}
        ></div>
        {/* right panel */}
        <div
          style={{ flex: 0.5, height: 22 }}
          className={styles.animatedBackground}
        ></div>
      </div>

      {/* middle panel */}
      <div style={{ display: "flex" }}>
        {/* left */}
        <div style={{ flex: 0.5 }}>
          <div
            style={{
              display: "flex",
              height: 55,
              marginTop: 23,
            }}
            className={styles.animatedBackground}
          ></div>
        </div>
        {/* right */}
        <div
          style={{
            display: "flex",
            height: 55,
            marginLeft: 9,
            marginTop: 23,
            flex: 0.5,
          }}
          className={styles.animatedBackground}
        ></div>
      </div>
      <div
        className={styles.animatedBackground}
        style={{
          display: "flex",
          height: 32,
          marginTop: 12,
        }}
      ></div>
    </div>
  );
};

export default LoadingBetForm;
