import React, { useEffect, useState } from "react";
import { Alert, Fade } from "react-bootstrap";
import ConfettiExplosion from "react-confetti-explosion";
import { Order } from "interfaces/commerce/order/order";

interface PurchaseBannerProps {
  orders: Order[];
}

const OrderCompletionBanner = ({ orders }: PurchaseBannerProps) => {
  const [showOrderCompletedBanner, setShowOrderCompletedBanner] = useState(true); // Initial state to show the alert
  useEffect(() => {
    const fadeTimer = setTimeout(() => {
      setShowOrderCompletedBanner(false);
    }, 10000);

    return () => clearTimeout(fadeTimer);
  }, []);

  return (
    <div className={"d-flex flex-column justify-content-center align-items-center"} style={{ width: "100%" }}>
      {orders.map((o) => (
        <Fade in={showOrderCompletedBanner} unmountOnExit key={o.id}>
          <div style={{ width: "100%", maxWidth: "500px" }}>
            <Alert
              variant="success"
              style={{
                width: "100%",
                maxWidth: "500px",
                textAlign: "center"
              }}
            >
              You just purchased {o.product.name}!
            </Alert>
          </div>
        </Fade>
      ))}
      <ConfettiExplosion
        zIndex={1001}
        particleCount={400}
        duration={4000}
        width={2000}
        style={{
          position: "absolute",
          top: "20%",
          left: "55%"
        }}
      />
    </div>
  );
};

export default OrderCompletionBanner;
