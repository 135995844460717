import React, { useState } from "react";
import UsernameFormModal from "home/username-form";
import { useAppSelector } from "state/hooks";
import { getUserState } from "state/slices/user-slice";
import BlockyBoldText from "components/custom-texts/blocky-bold-text";
import { PencilSquare } from "react-bootstrap-icons";


const Username = () => {
  const [showChangeUsernameModal, setShowChangeUsernameModal] = useState(false);
  const user = useAppSelector(getUserState);

  return (
    <div className="d-flex gap-1 justify-content-center align-items-center">
      <BlockyBoldText style={{ color: "white", fontSize: 20 }}>
        {user.user_name}
      </BlockyBoldText>
      <PencilSquare
        onClick={() => setShowChangeUsernameModal(true)}
        style={{ color: "white", width: 20, height: 20, cursor: "pointer" }}
      />
      <UsernameFormModal
        close={() => setShowChangeUsernameModal(false)}
        show={showChangeUsernameModal}
      />
    </div>
  );
};

export default Username;
