const defaultStyles = {
  fontFamily: "monospace",
  borderTopLeftRadius: 6,
  borderTopRightRadius: 6,
  borderBottomRightRadius: 6,
  borderBottomLeftRadius: 6,
  border: "1 solid lightgrey",
  boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 10px 0px",
  margin: 4,
  paddingLeft: 8,
  paddingRight: 2,
  width: 36,
  height: 43,
  fontSize: 32,
  lineHeight: "normal",
};

export const inputStyle = {
  ...defaultStyles,
  color: "black",
  backgroundColor: "white",
};

export const inputStyleInvalid = {
  ...defaultStyles,
  color: "#b94a48",
  backgroundColor: "#f2dede",
  borderColor: "#eed3d7",
};
