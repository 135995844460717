import BlockyBoldText from "components/custom-texts/blocky-bold-text";
import { useAuth } from "hooks/auth";
import { Mixpanel } from "hooks/mixPanel";
import { Button } from "react-bootstrap";
import { useAppDispatch } from "state/hooks";
import { clearUserState } from "state/slices/user-slice";
import { sparketBlack, secondaryColor } from "utils/constants";

const SignoutButton = () => {
  const auth = useAuth();
  const dispatch = useAppDispatch();
  return (
    <Button
      style={{
        backgroundColor: secondaryColor,
        color: sparketBlack,
        border: "none",
        borderRadius: "unset",
        height: 40,
        width: 150,
      }}
      onClick={() => {
        Mixpanel.track("Clicked Sign Out", {
          isSignedIn: auth.signedIn,
        });
        (window as any).xpjs?.logout();
        auth.signOut().then((res) => {
          auth.signedIn = false;
          dispatch(clearUserState());
        });
      }}
    >
      <BlockyBoldText>Sign Out</BlockyBoldText>
    </Button>
  );
};

export default SignoutButton;
