import BlockyHeavyText from "components/custom-texts/blocky-heavy-text";
import { Button, Modal } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "state/hooks";
import { close, getSparketTutorialState, } from "state/slices/sparket-tutorial-slice";
import SparketTutorialCarousel from "./carousel";
import sharedModalStyles from "assets/shared-stylesheets/modal.module.css";
import { Mixpanel } from "hooks/mixPanel";
import { useAuth } from "hooks/auth";
import { textColor } from "utils/constants";
import { getGroupStyles } from "state/slices/group-slice";

const SparketTutorial = () => {
  const show = useAppSelector(getSparketTutorialState);
  const dispatch = useAppDispatch();
  const auth = useAuth();
  const primaryColor = useAppSelector(getGroupStyles).primary_color;

  return (
    <Modal
      show={show}
      dialogClassName="modal-90w"
      onHide={() => dispatch(close())}
    >
      <Modal.Title
        style={{ justifyContent: "space-between", backgroundColor: primaryColor }}
        className={sharedModalStyles.modalTitle}
      >
        <div />
        <BlockyHeavyText style={{ color: textColor }}>Tutorial</BlockyHeavyText>
        <Button
          style={{ backgroundColor: primaryColor, border: "none" }}
          onClick={() => {
            Mixpanel.track("Closed Tutorial", {
              isSignedIn: auth.signedIn,
            });
            dispatch(close());
          }}
        >
          <BlockyHeavyText customStyles={{ color: textColor }}>X</BlockyHeavyText>
        </Button>
      </Modal.Title>
      <Modal.Body
        className={sharedModalStyles.modalBody}
        style={{ height: 420, borderColor: primaryColor }}
      >
        <SparketTutorialCarousel />
      </Modal.Body>
    </Modal>
  );
};

export default SparketTutorial;
