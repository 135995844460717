import { Entry } from "interfaces/entry";
import OrderedSelectionEntryListItem
  from "components/pool-details/entries/ordered-selection-entry-list/ordered-selection-entry-list-item";
import { useEffect, useMemo, useState } from "react";
import { getRequest } from "utils/httpClient";
import { getEntryPath } from "utils/backend-path-builders";
import { useAppSelector } from "state/hooks";
import { getGroupState } from "state/slices/group-slice";
import { PoolDetailsResponse } from "interfaces/pool-details-response";
import { POOL_STATUS } from "utils/constants";
import { betTypeExacta, BetTypeName, betTypeTrifecta, betTypeWin } from "interfaces/bet-type-name";

interface Props {
  contestants: any[];
  selectBet: any;
  poolType: BetTypeName;
  poolId: string;
  pool: PoolDetailsResponse;
  setIsFetchingEntry: () => void;
}

const OrderedSelectionEntryList = ({
                                     contestants,
                                     selectBet,
                                     poolType,
                                     poolId,
                                     pool,
                                     setIsFetchingEntry,
                                   }: Props) => {
  const groupId = useAppSelector(getGroupState).id;
  const poolTypes = [betTypeWin, betTypeExacta, betTypeTrifecta];
  const numberOfSelections = useMemo(() => poolTypes.indexOf(poolType) + 1, [poolType]);

  const [selectedContestantIds, setSelectedContestantIds] = useState(
    new Array(numberOfSelections)
  );

  useEffect(() => {
    // check if all ranks have selection
    let hasEmptyValues = false;
    for (let index = 0; index < numberOfSelections; index++) {
      const selection = selectedContestantIds[index];
      if (!selection) {
        hasEmptyValues = true;
        break;
      }
    }

    // No empty values means the user has selected a complete bet,
    // so we should fetch the entry
    if (!hasEmptyValues) {
      // fetch entry
      setIsFetchingEntry();
      getRequest(getEntryPath(groupId, poolId), {
        queryParams: {
          contestants: selectedContestantIds.join(";"),
        },
        skipIntegrationApi: true,
      }).then((result) => {
        selectBet(result);
      });
    } else {
      selectBet(undefined);
    }
  }, [groupId, numberOfSelections, poolId, selectBet, setIsFetchingEntry, selectedContestantIds]);

  const onSelectContestantId = (contestantId: string, rank: number) => {
    let newSelections = [...selectedContestantIds];
    // remove all selections on this contestant id
    newSelections.forEach((selection, index) => {
      if (selection === contestantId) {
        newSelections[index] = undefined;
      }
    });

    // add the selected one
    newSelections[rank - 1] = contestantId;
    setSelectedContestantIds(newSelections);
  };

  const totalSeedDistribution = contestants.reduce((acc, item) => {
    return acc + item.seed_distribution;
  }, 0);

  const winners = pool.winner_contestant_ids_places;
  let orderedContestants = contestants;
  if (winners) {
    // assign ranks
    contestants.forEach((c) => {
      for (let index = 1; index < numberOfSelections + 1; index++) {
        // for each rank starting at 1 we find the contestants with that rank
        // and assign their rank. There may be multiple for each rank
        // in the case of ties.
        const contestantIds = winners[index];
        contestants.forEach((c) => {
          if (contestantIds && contestantIds.includes(c.id)) {
            c.rank = index;
          }
        });
      }

      // order based on ranks
      orderedContestants = contestants.sort((a: any, b: any) => {
        const aRank = a.rank || numberOfSelections + 2;
        const bRank = b.rank || numberOfSelections + 2;
        if (aRank < bRank) {
          return -1;
        } else {
          return 1;
        }
      });
    });
  }

  return (
    <>
      {orderedContestants.map((contestant: any, index) => {
        const projectedWin =
          contestant.seed_distribution / totalSeedDistribution || 0;

        let winOdds = undefined;
        if (pool && pool.pool_types.includes("win")) {
          const entry = pool.entries?.find((entry: Entry) => {
            return entry.contestant_id === contestant.id;
          });

          winOdds = entry?.odds || 0;
        }

        return (
          <OrderedSelectionEntryListItem
            key={contestant.id}
            item={contestant}
            numberOfSelections={numberOfSelections}
            onSelectContestantId={onSelectContestantId}
            selectedContestantIds={selectedContestantIds}
            projectedWin={projectedWin}
            winOdds={winOdds}
            winnerRank={contestant.rank}
            allowRankSelection={POOL_STATUS.SETTLED !== pool.status} />
        );
      })}
    </>
  );
};

export default OrderedSelectionEntryList;
