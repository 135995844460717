import OpenSansText from "components/custom-texts/open-sans-text";
import { useAppSelector } from "state/hooks";
import { getGroupState } from "state/slices/group-slice";

const AllowedGeolocationsText = () => {
  const { allowed_geolocations } = useAppSelector(getGroupState).settings;

  if (!allowed_geolocations) {
    return null;
  }

  return (
    <OpenSansText>
      To make a pick in this pool you must be in one of the following states:{" "}
      <span style={{ fontWeight: "bold" }}>
        {allowed_geolocations.join(", ")}
      </span>
    </OpenSansText>
  );
};
export default AllowedGeolocationsText;
