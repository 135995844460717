import Modal from "./modal";
import Bowser, { Parser } from "bowser";
import { Mixpanel } from "hooks/mixPanel";

// earliest existing versions. If version is not set
// then we don't allow the browser. This happens on instagram and facebook
// embedded browsers, which we do not allow.
export const SAFARI_SUPPORTED_VERSION = "1";
export const CHROME_SUPPORTED_VERSION = "0";
export const FIREFOX_SUPPORTED_VERSION = "5";
export const EDGE_SUPPORTED_VERSION = "20";
export const SAMSUNG_INTERNET_SUPPORTED_VERSION = "0";

const Container = () => {
  const parser: Parser.Parser = Bowser.getParser(window.navigator.userAgent);

  const isValidBrowserVersion =
    parser.satisfies({
      safari: `>=${SAFARI_SUPPORTED_VERSION}`,
      chrome: `>=${CHROME_SUPPORTED_VERSION}`,
      firefox: `>=${FIREFOX_SUPPORTED_VERSION}`,
      edge: `>=${EDGE_SUPPORTED_VERSION}`,
      samsung_internet: `>=${SAMSUNG_INTERNET_SUPPORTED_VERSION}`,
    }) || false;

  const userAgentString = parser.getUA();
  const isInstagram = userAgentString.includes("Instagram");
  const isFacebookIos = userAgentString.includes("FBAN");
  const isFacebookAndroid = userAgentString.includes("FB_IAB");
  const isLinkedin = userAgentString.includes("LinkedInApp");
  const isValidBrowser =
    isValidBrowserVersion &&
    !isFacebookIos &&
    !isFacebookAndroid &&
    !isInstagram &&
    !isLinkedin;

  if (!isValidBrowser) {
    Mixpanel.track("Unsupported Browser", {
      browserName: parser.getBrowserName(),
      browserVersion: parser.getBrowserVersion(),
      userAgentString: userAgentString,
    });
  }
  return <Modal show={!isValidBrowser} />;
};

export default Container;
