import { Auth } from "aws-amplify";
import { GROUP_SLUG, TOKEN, USER_ID } from "utils/constants";
import { isIntegratedApp } from "utils/httpClient";
import * as storage from "utils/local-storage";

export const authConfig = {
  Auth: {
    region: process.env.REACT_APP_COGNITO_CLIENT_ID,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
    authenticationFlowType: "USER_SRP_AUTH",
  },
  oauth: {
    domain: process.env.REACT_APP_OAUTH_DOMAIN,
    scope: [
      "phone",
      "openid",
      "profile",
      "email",
      "aws.cognito.signin.user.admin",
    ],
    redirectSignIn: process.env.REACT_APP_HOST_URL,
    redirectSignOut: process.env.REACT_APP_HOST_URL,
    responseType: "code", // or 'token', note that REFRESH token will only be generated when the responseType is code
  },
  API: {
    endpoints: [
      {
        name: "api",
        endpoint: process.env.REACT_APP_BACKEND_URL,
        custom_header: async () => {
          try {
            const integration = isIntegratedApp();
            const groupSlug = storage.local.getItem(GROUP_SLUG);

            const headers = {
              "x-client-id": integration ? groupSlug : "web",
            };

            if (integration) {
              headers["x-user-id"] = storage.local.getItem(USER_ID);
              headers["x-login-token"] = storage.local.getItem(TOKEN);
            } else {
              headers["Authorization"] = `Bearer ${(await Auth.currentSession())
                .getIdToken()
                .getJwtToken()}`;
            }

            return headers;
          } catch (e) {
            return {};
          }
        },
      },
    ],
  },
};
