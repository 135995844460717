const AREA_CODE_LENGTH = 3;
const HYPHEN_NOT_NEEDED_LENGTH = 6;

const formatPhoneNumber = (value: string) => {
  if (!value) return value;
  const phoneNumber = value.replace(/[^\d]/g, "");
  const phoneNumberLength = phoneNumber.length;
  if (phoneNumberLength <= AREA_CODE_LENGTH) return phoneNumber;
  if (phoneNumberLength <= HYPHEN_NOT_NEEDED_LENGTH) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
    3,
    6
  )}-${phoneNumber.slice(6, 10)}`;
};

export default formatPhoneNumber;
