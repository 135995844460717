import { Modal } from "react-bootstrap";
import { Bet } from "interfaces/bet";
import BetDetailsContent from "components/bet-details-page/bet-details-content";

interface Props {
  bet: Bet | undefined;
  hide: () => void;
}
const BetDetailsModal = ({ bet, hide }: Props) => {
  return (
    // Experiment here is enforceFocus. I want to see if it fixes the auto scrolling
    // behavior in an iframe.
    <Modal enforceFocus={false} onHide={hide} show={!!bet}>
      <div
        style={{
          paddingBottom: 10,
          background: "white",
        }}
      >
        {bet && <BetDetailsContent hide={hide} bet={bet} loading={false} />}
      </div>
    </Modal>
  );
};

export default BetDetailsModal;
