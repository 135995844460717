import BlockyBoldText from "components/custom-texts/blocky-bold-text";
import { BaseSyntheticEvent, useState } from "react";
import { Button } from "react-bootstrap";
import NumberFormat from "react-number-format";
import { getSelfLimitationsPath } from "utils/backend-path-builders";
import { formatAsDollars, formattedCurrencyToNumber, } from "utils/formatter-utils/currency-formatter";
import { postRequest } from "utils/httpClient";
import ConfirmForm from "./confirm-form";

interface Props {
  current: number | undefined;
  next: number | undefined;
  label: string;
  limitationType: string;
  limitTerm: string;
  refreshData: () => void;
}

const LimitationForm = ({
  current,
  next,
  label,
  limitationType,
  limitTerm,
  refreshData,
}: Props) => {
  const [newValue, setNewValue] = useState<number>();
  const [showConfirmButtons, setShowConfirmButtons] = useState(false);
  const [saving, setSaving] = useState(false);
  const currentLimit = current ? formatAsDollars(current) : "none";

  const onConfirmSubmit = async () => {
    setSaving(true);
    const body = {
      limitation_type: limitationType,
      limit_amount: newValue,
      limit_term: limitTerm,
    };
    await postRequest(getSelfLimitationsPath(), { body });
    refreshData();
    setSaving(false);
    setNewValue(undefined);
    setShowConfirmButtons(false);
  };

  if (showConfirmButtons && newValue) {
    const displayLabel = label.toLowerCase();
    const formattedNewLimit = formatAsDollars(newValue);

    const limitIncreaseText = `You are trying to raise your ${displayLabel} from ${currentLimit} to ${formattedNewLimit}. This will require a 24 hour waiting period to take effect.`;
    const defaultText = `Are you sure you want to set your ${displayLabel} to ${formattedNewLimit}?`;

    const isLimitIncreaseRequest = current && current < newValue;

    return (
      <ConfirmForm
        explanationText={
          isLimitIncreaseRequest ? limitIncreaseText : defaultText
        }
        onConfirm={onConfirmSubmit}
        onCancel={() => {
          setShowConfirmButtons(false);
          setNewValue(undefined);
        }}
        saving={saving}
      />
    );
  }

  const handleSaveClick = () => setShowConfirmButtons(true);
  return (
    <div data-testid={"limitation-form"}>
      <BlockyBoldText>{label}</BlockyBoldText>
      <div>Current limit: {currentLimit}</div>
      {next && <div>Requested limit: {formatAsDollars(next)}</div>}
      <NumberFormat
        prefix="$"
        decimalScale={0}
        thousandSeparator
        inputMode="decimal"
        fixedDecimalScale
        allowNegative={false}
        onChange={(e: BaseSyntheticEvent) => {
          setNewValue(formattedCurrencyToNumber(e.target.value));
        }}
      />
      &nbsp; &nbsp; &nbsp;
      <Button disabled={saving || !newValue} onClick={handleSaveClick}>
        Save
      </Button>
    </div>
  );
};

export default LimitationForm;
