import BlockyHeavyText from "components/custom-texts/blocky-heavy-text";
import { ToggleButton } from "react-bootstrap";

const RankSelector = ({
  numberOfSelections,
  contestantId,
  onSelectContestantId,
  selectedContestantIds,
  winnerRank,
}: any) => {
  const ranks = Array.apply(null, Array(numberOfSelections)).map(function (
    x,
    i
  ) {
    return i + 1;
  });
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "space-evenly",
      }}
      defaultValue={undefined}
      id={contestantId}
    >
      {ranks.map((rank, index) => {
        const value = `${rank}_${contestantId}`;
        const checked = selectedContestantIds[rank - 1] === contestantId;

        const uniqueIdentifier = `${contestantId}_${index}_${numberOfSelections}`;
        let color = "lightgray";
        if (rank === winnerRank) {
          color = "#1ba628";
        }

        return (
          <div
            key={index}
            style={
              winnerRank
                ? {
                    backgroundColor: color,
                    borderRadius: 100,
                    color: "black",
                    border: "1px solid black",
                  }
                : {}
            }
          >
            <ToggleButton
              key={uniqueIdentifier}
              id={uniqueIdentifier}
              type="checkbox"
              name={uniqueIdentifier}
              style={{
                borderRadius: "100%",
                height: 38,
                width: 38,
                boxShadow: "none",
              }}
              variant="outline-success"
              value={value}
              checked={checked}
              disabled={winnerRank !== undefined}
              onChange={(e) => {
                if (checked) {
                  // item being clicked is selected so we want to unselect
                  onSelectContestantId(undefined, rank);
                } else {
                  onSelectContestantId(contestantId, rank);
                }
              }}
            >
              <BlockyHeavyText style={{ color: winnerRank ? "black" : "" }}>
                {rank}
              </BlockyHeavyText>
            </ToggleButton>
          </div>
        );
      })}
    </div>
  );
};

export default RankSelector;
