import { fiftyYardImgUrl, sparketBlack } from "utils/constants";
import Logo from "./logo";

interface Props {
  children: JSX.Element[] | JSX.Element;
}
const Container = ({ children }: Props) => {
  return (
    <div
      style={{
        backgroundImage: `url(${fiftyYardImgUrl})`,
        backgroundSize: "contain",
        backgroundColor: sparketBlack,
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Logo />
      {children}
    </div>
  );
};

export default Container;
