import { CheckRequestData, WebSdk } from "@xpointtech/xpoint-js";
import { WebSdkClientImpl } from "@xpointtech/xpoint-js/dist/src/lib/WebSdkClientImpl";

class XpjsService {
  private xpjs: WebSdkClientImpl;

  constructor() {
    this.xpjs = new WebSdk();
  }

  setUserId(userId: string): Promise<string> {
    return this.xpjs.setUserId(userId, process.env.REACT_APP_XPOINT_CLIENT_KEY);
  }

  checkStatus(): Promise<CheckRequestData> {
    return this.xpjs.checkStatus();
  }

  startGame(): Promise<void> {
    return this.xpjs.wageringStart();
  }

  stopGame(): Promise<void> {
    return this.xpjs.wageringEnd();
  }

  logout(): Promise<void> {
    return this.xpjs.logout();
  }
}

export default XpjsService;
