import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "state/hooks";
import { getGroupState } from "state/slices/group-slice";
import { fetchContests, fetchUserContests, getContestsState, } from "state/slices/contests-slice";
import { useAuth } from "hooks/auth";

const useContestData = () => {
  const dispatch = useAppDispatch();
  const groupId = useAppSelector(getGroupState).id;
  const { contests, joinedContests } = useAppSelector(getContestsState);
  const [userContestsDataIsStale, setUserContestDataIsStale] = useState(false);
  const auth = useAuth();

  useEffect(() => {
    if (!groupId) return;

    dispatch(fetchContests(groupId));
  }, [dispatch, groupId]);

  useEffect(() => {
    if (!groupId) return;

    if (auth.signedIn) {
      dispatch(fetchUserContests(groupId));
      setUserContestDataIsStale(false);
    }
  }, [dispatch, groupId, auth.signedIn, userContestsDataIsStale]);
  return {
    contests,
    joinedContests,
    refetchUserContests: () => setUserContestDataIsStale(true),
  };
};

export default useContestData;
