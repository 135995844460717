import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import UsersResponse, { IDENTITY_VERIFICATION_STATUS, } from "interfaces/users-response";
import { getUserPath } from "utils/backend-path-builders";
import { DEFAULT_AVATAR_SRC, FAILED, IDLE, LOADING, SUCCEEDED } from "utils/constants";
import { getRequest, isIntegratedApp } from "utils/httpClient";
import { RootState } from "../store";

export interface UserState extends UsersResponse {
  fetchUserState: "idle" | "loading" | "succeeded" | "failed";
}

export const initialState: UserState = {
  avatar: "",
  badges: [],
  bio: "",
  created_at: "",
  id: "",
  last_login: "",
  last_seen: "",
  password_last_changed: "",
  status: "",
  super_admin: false,
  user_name: "",
  validation_status: IDENTITY_VERIFICATION_STATUS.NOT_STARTED,
  fetchUserState: IDLE,
  validation_request_id: "",
  notifications: { email: {} },
};

export const fetchUser = createAsyncThunk("user/fetch", async () => {
  if (isIntegratedApp()) {
    return {};
  }
  const path = getUserPath();
  const response: UsersResponse = await getRequest(path, { skipIntegrationApi: true });

  if (response.avatar === "") {
    response.avatar = DEFAULT_AVATAR_SRC;
  }
  if (!response.badges) {
    response.badges = [];
    // for some season weird reason the server returns base 64 encoded string ['url1', 'url2', ...]
  } else if (typeof response.badges === 'string') {
    try {
      response.badges = JSON.parse(atob(response.badges)) as string[];
    } catch (e) {
      console.warn("Failed to decode user's badges", e);
      response.badges = [];
    }
  } else if (!Array.isArray(response.badges)) {
    console.warn("badges field is of a wrong type");
    response.badges = [];
  }
  return response;
});

const slice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setEmailConfig: (state, action) => {
      state.notifications = action.payload.notifications;
    },
    clearUserState: () => initialState,
    setAvatar: (state, action) => {
      state.avatar = action.payload;
    },
    setBadges: (state, action) => {
      state.badges = action.payload;
    }
  },
  extraReducers(builder) {
    builder.addCase(fetchUser.pending, (state, _) => {
      state.fetchUserState = LOADING;
    });
    builder.addCase(fetchUser.fulfilled, (_, action) => {
      return { ...(action.payload) as UsersResponse, fetchUserState: SUCCEEDED };
    });
    builder.addCase(fetchUser.rejected, (state, _) => {
      state.fetchUserState = FAILED;
    });
  },
});

export const getUserState = (state: RootState) => {
  return state.user;
};

export const getVerificationStatus = (state: RootState) => {
  return getUserState(state).validation_status;
};

export const { setEmailConfig, clearUserState, setBadges, setAvatar } = slice.actions;

export default slice.reducer;
