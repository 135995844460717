export enum UserGroupMembershipStatus {
  /**
   * The user has not requested to join the group.
   */
  NOT_REQUESTED = "not",

  /**
   * The user's request to join the group is pending approval.
   */
  PENDING = "pending",

  /**
   The user's request to join the group is pending identity verification.
   */
  PENDING_IDENTITY_VERIFICATION = "pending_identity_verification",

  /**
   * The user is approved and is a member of the group.
   */
  APPROVED = "approved",
}