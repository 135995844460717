import "./drawer-styles.css";
import DrawerSection from "components/drawer/drawer-section";
import { useAuth } from "hooks/auth";
import { useAppSelector } from "state/hooks";
import { getGroupState, getGroupStatus } from "state/slices/group-slice";
import { UserGroupMembershipStatus } from "utils/userGroupMembershipStatus";
import { getIntegratedAppState } from "state/slices/integrated-app-slice";

const NavigationLinks = () => {
  const { signedIn } = useAuth();
  const integratedAppState = useAppSelector(getIntegratedAppState);
  const approvedGroupStatus = useAppSelector(getGroupStatus) === UserGroupMembershipStatus.APPROVED;
  const {
    bankroll_page: bankrollPage,
    settings_page: settingsPage,
    help_page: helpPage,
    confirm_results: confirmResults,
    leaderboard,
  } = useAppSelector(getGroupState).customization;
  return (
    <>
      <div>
        <DrawerSection icon="home" label="Home" path="/" />
        {bankrollPage && approvedGroupStatus && (
          <DrawerSection icon="bankroll" label="Bankroll" path="/bankroll" />
        )}
        {signedIn && (
            <DrawerSection icon="mybets" label="My Picks" path="/mypicks" />
        )}
        {signedIn && (localStorage.getItem("skill-contest") === "true") &&
          <DrawerSection icon="mycontests" label="My Contests" path="/mycontests" />
        }
        {leaderboard && (
          <DrawerSection
            icon="leaderboard"
            label="Leaderboard"
            path="/leaderboard"
          />
        )}
        {approvedGroupStatus && confirmResults && (
          <DrawerSection
            icon="confirm"
            label="Confirm Results"
            path="/confirm-results"
          />
        )}
        {helpPage && <DrawerSection icon="help" label="Help" path="/help" />}
        {integratedAppState.isIntegratedApp && settingsPage && (
          <DrawerSection
            icon="settings"
            label="Settings"
            path="/notification-settings"
          />
        )}
      </div>
    </>
  );
};
export default NavigationLinks;
