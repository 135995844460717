export const timezone = {
  get offsetHours() {
    const offsetInMinutes = new Date().getTimezoneOffset();
    const offsetInHours = -offsetInMinutes / 60;
    const sign = offsetInHours >= 0 ? "+" : "-";
    const formattedOffset = Math.abs(offsetInHours).toString().padStart(2, "0");
    return `${sign}${formattedOffset}`;
  },
  get offsetMinutes() {
    return new Date().getTimezoneOffset();
  }
};

export const calculateAge = (birthDate: Date): number => {
  const today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();

  const hasNotHadBirthdayThisYear =
    today.getMonth() < birthDate.getMonth() ||
    (today.getMonth() === birthDate.getMonth() && today.getDate() < birthDate.getDate());

  if (hasNotHadBirthdayThisYear) {
    age--;
  }

  return age;
};