import BlockyHeavyText from "components/custom-texts/blocky-heavy-text";
import sharedStyles from "assets/shared-stylesheets/contestant-list-item.module.css";
import styles from "./ordered-selection-entry-list-styles.module.css";
import entryItemStyles from "../entry-item-styles.module.css";
import { useRef } from "react";
import RankSelector from "./rank-selector";

interface Props {
  item: any;
  setHeight?: React.Dispatch<React.SetStateAction<number>>;
  numberOfSelections: number;
  onSelectContestantId: (contestantId: string, rank: number) => void;
  selectedContestantIds: string[];
  projectedWin: number;
  winOdds: number | undefined;
  winnerRank: number;
  allowRankSelection: boolean;
}

const OrderedSelectionEntryListItem = ({
                item,
                setHeight,
                numberOfSelections,
                onSelectContestantId,
                selectedContestantIds,
                projectedWin,
                winOdds,
                winnerRank,
                allowRankSelection
              }: Props) => {
  const ref = useRef(null);

  const isWinner = winnerRank <= numberOfSelections;

  let backgroundColor: string = "lightgray";
  if (allowRankSelection && !isWinner) {
    backgroundColor = "white";
  }

  return (
    <div
      id="div-Item-clickEntryItem"
      onClick={() => {
        setHeight && setHeight((ref.current as any)?.clientHeight);
      }}
    >
      <div
        style={{ backgroundColor, border: isWinner ? "3px solid #1ba628" : "" }}
        ref={ref}
        className={`${sharedStyles.itemContainer} ${entryItemStyles.container}`}
      >
        <div className={`${sharedStyles.itemLeftColumnContainer} ${styles.orderedSelectionItemLeftColumnContainer}`}>
          <BlockyHeavyText
            className={entryItemStyles.titleText}
          >{`${item.name}`}</BlockyHeavyText>
          <div className={sharedStyles.inline}>
            <BlockyHeavyText className={`${sharedStyles.activeBetsText} ${entryItemStyles.labelText}`}>
              Projected Win:&nbsp;
            </BlockyHeavyText>
            <BlockyHeavyText className={entryItemStyles.oddsText}>
              {(projectedWin * 100).toFixed(1)}%
            </BlockyHeavyText>
          </div>
          <div className={sharedStyles.inline}>
            {winOdds !== undefined ? (
              <>
                <BlockyHeavyText className={entryItemStyles.labelText}>
                  Win Odds:&nbsp;
                </BlockyHeavyText>
                <BlockyHeavyText className={entryItemStyles.oddsText}>
                  {`${winOdds.toFixed(1)}x`}
                </BlockyHeavyText>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
        {(isWinner || allowRankSelection) &&
          <div
            className={styles.orderedSelectionItemRightColumnContainer}
            style={{ borderLeft: "2px solid lightgray" }}
          >
            <RankSelector
              numberOfSelections={numberOfSelections}
              contestantId={item.id}
              onSelectContestantId={onSelectContestantId}
              selectedContestantIds={selectedContestantIds}
              winnerRank={winnerRank}
            />
          </div>}

      </div>
    </div>
  );
};

export default OrderedSelectionEntryListItem;
