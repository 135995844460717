import { useCallback } from 'react';
import { WJAL_REMOTE_GROUP_ID } from 'utils/constants';

export const useTrackEvent = (groupId: string, event: string): (() => void) => {
  const trackEvent = useCallback(() => {
    if (groupId !== WJAL_REMOTE_GROUP_ID) return;

    const inlineScript = `
      !function(v,i,b,e,c,o){
        if(!v[c]) {
          var s=v[c]=function() { s.process?s.process.apply(s,arguments):s.queue.push(arguments) };
          s.queue=[],s.b=1*new Date;
          var t=i.createElement(b);
          t.async=!0;
          t.src=e;
          var n=i.getElementsByTagName(b)[0];
          n.parentNode.insertBefore(t,n);
        }
      }(window,document,"script","https://s.vibe.co/vbpx.js","vbpx");
      
      vbpx('init', 'xuMaYh');
      vbpx('event', '${event}');
    `;

    const script = document.createElement('script');
    script.innerHTML = inlineScript;
    document.body.appendChild(script);

    script.onerror = () => {
      console.error('Failed to load the event tracking script.');
    };

  }, [groupId]);

  return trackEvent;
};
