import { Modal } from "react-bootstrap";
import BlockyHeavyText from "components/custom-texts/blocky-heavy-text";
import { XPOINT_NOT_FOUND } from "utils/constants";
import { useEffect, useState } from "react";
import sharedModalStyles from "assets/shared-stylesheets/modal.module.css";
import { useAppSelector } from "state/hooks";
import { getGroupState } from "state/slices/group-slice";
import useGeolocation from "hooks/useGeolocation";
import styles from "./modal-styles.module.css";
import { initialize } from "app-setup/initialize-geolocation";
import ErrorMessage from "./error-message";
import XpointNotRunningText from "./xpoint-not-running-text";
import AllowedGeolocationsText from "./allowed-geolocations-text";
import RetryButton from "./RetryButton";
import { getUserState } from "state/slices/user-slice";
import getCustomGeolocationErrors from "hooks/customGeolocationErrors";
import { CheckRequestStatus } from "@xpointtech/xpoint-js";

interface Props {
  show: boolean;
  hide: () => void;
}

const GeolocationModal = ({ show, hide }: Props) => {
  const { checkStatus } = useGeolocation();
  const { enable_geolocation } = useAppSelector(getGroupState).settings;
  const [error, setError] = useState("");
  const [xpointRunning, setXpointRunning] = useState(false);
  const [loading, setLoading] = useState(false);
  const userId = useAppSelector(getUserState).id;
  const [xpointInitialized, setXpointInitialized] = useState(false);

  useEffect(() => {
    if (!show) {
      setXpointInitialized(false);
    }
  }, [show]);

  const retry = async () => {
    setLoading(true);

    try {
      const result = await checkStatus();

      if (!xpointInitialized) {
        await initialize(userId);
        setXpointInitialized(true);
      }

      switch (result.status) {
        case XPOINT_NOT_FOUND:
          setXpointRunning(false);
          setError(getCustomGeolocationErrors(result));
          break;
        case CheckRequestStatus.DENIED:
          setXpointRunning(true);
          setError(getCustomGeolocationErrors(result));
          break;
        case CheckRequestStatus.WAITING:
          setXpointRunning(true);
          setError("waiting for xpoint");
          break;
        case CheckRequestStatus.ALLOWED:
          hide();
          break;
        default:
          break;
      }
    } catch {
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal show={show && enable_geolocation} onHide={hide} centered>
      <Modal.Title
        className={`${sharedModalStyles.modalTitle} ${styles.modalTitle}`}
      >
        <div />
        <BlockyHeavyText>Location verification</BlockyHeavyText>
        <div />
      </Modal.Title>
      <Modal.Body className={styles.modalBody}>
        <div className={styles.bodyWrapper}>
          <AllowedGeolocationsText />
          {!xpointRunning && <XpointNotRunningText />}
          <div></div>
          <div className={styles.errorsWrapper}>
            <ErrorMessage content={error} show={error !== ""} />
            <RetryButton loading={loading} onClick={retry} />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default GeolocationModal;
