import BlockyMediumText from "components/custom-texts/blocky-medium-text";
import { ExclamationCircle } from "react-bootstrap-icons";
import { Dispatch, SetStateAction } from "react";
import styles from "../badge-styles.module.css";

interface Props {
  message: string;
  showGeolocationModal: Dispatch<SetStateAction<boolean>>;
}
const ErrorBadgeContent = ({ message, showGeolocationModal }: Props) => {
  return (
    <div className={styles.badgeContentWrapper}>
      <ExclamationCircle />
      <div className={styles.iconSpacing}></div>
      <BlockyMediumText>{message}</BlockyMediumText>
      <a
        onClick={(e) => showGeolocationModal(true)}
        href="#"
        className={styles.infoLink}
      >
        info
      </a>
    </div>
  );
};

export default ErrorBadgeContent;
