import { Carousel } from "react-bootstrap";
import { CaretLeftFill, CaretRightFill } from "react-bootstrap-icons";
import ContestItem from "./contest-item";
import useContestData from "hooks/useContestData";

const Card = () => {
  const { contests } = useContestData();

  return (
    <div>
      <Carousel
        prevIcon={<CaretLeftFill style={{ color: "black" }} />}
        nextIcon={<CaretRightFill style={{ color: "black" }} />}
        indicators={false}
        wrap={false}
        interval={null}
      >
        {/* we will only have one contest to start out but this should work if we have more. */}
        {contests.map((contest) => {
          return (
            <Carousel.Item key={contest.id}>
              <ContestItem contest={contest} />
            </Carousel.Item>
          );
        })}
      </Carousel>
    </div>
  );
};

export default Card;
