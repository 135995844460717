import { Image } from "react-bootstrap";
import { useAppSelector } from "state/hooks";
import { getAvatarModalState } from "state/slices/avatar-modal-slice";
import React from "react";
import styles from "./styles.module.css";
import plusLogo from "icons/plus.svg";

const BadgesSection: React.FC = () => {
  const avatarModalState = useAppSelector(getAvatarModalState);

  const badges = [...(avatarModalState.selectedBadges || [])];

  while (badges.length < 4) {
    badges.push(plusLogo);
  }

  return (
    <div className={styles.badgesDisplay}>
      {(badges || []).map((badge, index) => (
        <div key={index} style={{ border: "1px solid white", aspectRatio: "1/1" }}>
          {badge === plusLogo ? (
            <div className={styles.plusContainer}>
              <Image
                src={badge}
                className={styles.plus}
              />
            </div>
          ) : (
            <Image
              src={badge}
              className={styles.badge}
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default BadgesSection;