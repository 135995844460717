import listItemStyles from "assets/shared-stylesheets/sparket-list-item.module.css";
import BlockyHeavyText from "components/custom-texts/blocky-heavy-text";
import EnterContestButton from "./enter-contest-button";
import styles from "./styles.module.scss";
import epochToFormattedDateTime from "utils/formatter-utils/date-formatter";
import { Link } from "react-router-dom";
import BlockyMediumText from "components/custom-texts/blocky-medium-text";
import { sparketDarkRed } from "utils/constants";
import { useAppDispatch, useAppSelector } from "state/hooks";
import { getGroupState } from "state/slices/group-slice";
import { useAuth } from "hooks/auth";
import BlockyBoldText from "components/custom-texts/blocky-bold-text";
import { Checkmark } from "react-checkmark";
import { isJoined } from "state/slices/contests-slice";
import { openContestTerms } from "state/slices/legal-modal-slice";
import { UserGroupMembershipStatus } from "utils/userGroupMembershipStatus.ts";

const disabledMessage = (joinedGroup: boolean, signedIn: boolean) => {
  if (!signedIn) {
    return "You must be signed in to enter";
  }

  if (!joinedGroup) {
    return "You must join the group to enter";
  }

  return "";
};

interface Props {
  contest: any;
}

const ContestItem = ({ contest }: Props) => {
  const group = useAppSelector(getGroupState);
  const dispatch = useAppDispatch();
  const user = useAuth();
  const {
    id,
    max_entries,
    total_entries,
    entry_fee,
    name,
    headline,
    end_at,
  } = contest;
  const joinedContest = useAppSelector((s) => isJoined(s, id));

  const joinedGroup = group.status === UserGroupMembershipStatus.APPROVED;
  const signedIn = user.signedIn;

  const disabledText = disabledMessage(joinedGroup, signedIn);
  return (
    <div
      className={[listItemStyles.itemContainer, styles.pageWrapper].join(" ")}
    >
      {/* title/headline */}
      <div>
        <BlockyHeavyText
          style={{
            width: "100%",
            fontSize: 12,
          }}
        >
          <div
            style={{
              paddingLeft: 25,
              paddingRight: 25,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                fontSize: 16,
                justifyContent: "center",
                display: "flex",
              }}
            >
              {name}
            </div>
            <div
              style={{
                justifyContent: "center",
                display: "flex",
                fontSize: 11,
              }}
              className={styles.entryText}
            >
              {headline}
            </div>
          </div>
        </BlockyHeavyText>
      </div>

      <div className={styles.contestDetailsWrapper}>
        {/* left panel */}
        <div className={styles.centeredColumn}>
          <BlockyHeavyText className={styles.entryCount}>
            {max_entries ? `${total_entries}/${max_entries}` : total_entries}
          </BlockyHeavyText>
          <BlockyHeavyText className={styles.entryText}>
            Entries
          </BlockyHeavyText>
        </div>

        {/* divider */}
        <div className={styles.divider} />

        {/* middle pannel */}
        <div className={styles.centeredColumn}>
          <BlockyHeavyText className={styles.entryCount}>
            {entry_fee && entry_fee > 0 ? "$" + entry_fee : "Free"}
          </BlockyHeavyText>
          <BlockyHeavyText className={styles.entryText}>Entry</BlockyHeavyText>
        </div>

        {/* divider */}
        <div className={styles.divider} />

        {/* right panel */}
        <div className={styles.centeredColumn}>
          <Link to="#" onClick={() => dispatch(openContestTerms())}>
            <BlockyMediumText style={{ fontSize: 12, marginBottom: 10 }}>
              View Prizes
            </BlockyMediumText>
          </Link>
          <EnterContestButton contest={contest} />
          {joinedContest && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                color: "var(--sparket-green)",
                fontSize: 12,
              }}
            >
              <Checkmark size="small" /> &nbsp;
              <BlockyBoldText>Joined</BlockyBoldText>
            </div>
          )}
        </div>
      </div>

      {/* bottom bar */}
      <div className={styles.bottomBar}>
        <BlockyHeavyText className={styles.entryText}>
          <span style={{ fontSize: 10 }}>Closes: &nbsp;</span>
          <BlockyMediumText style={{ color: "black" }}>
            {epochToFormattedDateTime(end_at)}
          </BlockyMediumText>
        </BlockyHeavyText>
        {disabledText && (
          <BlockyMediumText style={{ color: sparketDarkRed, fontSize: 12 }}>
            {disabledText}
          </BlockyMediumText>
        )}
      </div>
    </div>
  );
};

export default ContestItem;
