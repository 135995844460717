import { API } from "aws-amplify";
import * as storage from "utils/local-storage";
import { IS_INTEGRATED_APP } from "./constants";
import { integratedGet, integratedPost, integratedPut, } from "./integrated-app-http-client";

const API_NAME = "api";

export const isIntegratedApp = () => {
  return storage.local.getItem(IS_INTEGRATED_APP) === "true";
};

export const getRequest = (path: string, options?: any) => {
  if (isIntegratedApp() && !options?.skipIntegrationApi)
    return integratedGet(path, options);

  const myInit = {
    queryStringParameters: options?.queryParams,
    ...options,
  };
  return API.get(API_NAME, path, myInit);
};

export const postRequest = (path: string, options?: any) => {
  if (isIntegratedApp()) return integratedPost(path, options);

  const myInit = {
    body: options?.body,
    queryStringParameters: options?.queryParams,
  };
  return API.post(API_NAME, path, myInit);
};

export const putRequest = (path: string, options: { body?: any } = {}) => {
  if (isIntegratedApp()) return integratedPut(path, options);

  const myInit = {
    body: options.body,
  };
  return API.put(API_NAME, path, myInit);
};
