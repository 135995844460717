import { getSubmitAvaterPath } from "utils/backend-path-builders";
import { postRequest } from "utils/httpClient";

async function uploadFile(url: string, file: any, headers: Headers) {
  const response = await fetch(url, {
    method: "PUT",
    headers: headers,
    body: file,
  });
  return response;
}

const SubmitAvatar = (file: any) => {
  return postRequest(getSubmitAvaterPath(), {
    body: {
      file_name: file.name,
    },
  })
    .then((data) => {
      if (data.url === undefined) {
        console.log("Missing upload url");
      } else {
        const presignedUrl = data.url;
        const headers = new Headers();
        headers.append("Content-Type", file.type);
        headers.append("x-amz-acl", "public-read");
        uploadFile(presignedUrl, file, headers)
          .then((data) => {
            console.log("Printing uploadImage result: ", data.url);
          })
          .catch((error) => {
            console.log("Error: ", error);
          });
      }
    })
    .catch((error) => {
      console.warn("Error when generating upload url", error);
    });
};

export default SubmitAvatar;
