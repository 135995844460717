import LoadingSpinner from "components/loading-spinner.tsx";
import { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { getRequest } from "utils/httpClient";

interface Props {
  url: string;
}
const LegalPage = ({ url }: Props) => {
  const [loading, setLoading] = useState(true);
  const [body, setBody] = useState("");
  useEffect(() => {
    getRequest(url)
      .then((data) => {
        setBody(data.doc as string);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [url]);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div style={{ padding: 20 }}>
      <ReactMarkdown>{body}</ReactMarkdown>
    </div>
  );
};

export default LegalPage;
