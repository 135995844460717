/**
 * @fileoverview AvatarDisplay component is the main display for the user's avatar and badges.
 */


import { useAppSelector } from "state/hooks";
import { getUserState } from "state/slices/user-slice";
import React from "react";
import { Image } from "react-bootstrap";
import styles from "./styles.module.css";

/**
 * AvatarDisplay component loads in avatar and badges from user state
 * and displays avatar as large image with row of badges below.
 * @constructor
 */
const AvatarDisplay: React.FC = () => {
  const userState = useAppSelector(getUserState);

  return (
    <div className={styles.displayGrid}>
      <div className={styles.avatar}>
        <Image
          src={userState.avatar}
          className={styles.image}
        />
      </div>
      <div className={styles.badges}>
        {(userState.badges || []).map((badge, index) => (
          <Image
            key={index}
            src={badge}
            className={styles.badge}
          />
        ))}
      </div>
    </div>
  );

};


export default AvatarDisplay;

