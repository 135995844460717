// Hook (auth.js)
import { createContext, useContext, useEffect, useState } from "react";
import { Amplify, Auth, Hub } from "aws-amplify";
import { authConfig } from "hooks/authConfig";
import { User } from "interfaces/user";
import { useAppDispatch, useAppSelector } from "state/hooks";
import { close, showConfirmTwoFactor } from "state/slices/login-modals-slice";
import { toast } from "react-toastify";
import { SMS, SMS_MFA, } from "utils/constants";
import { getRequest, postRequest } from "utils/httpClient";
import { getFailedLoginPath, getGroupDetailsPath, } from "utils/backend-path-builders";
import { setGroup } from "state/slices/group-slice";
import { getIntegratedAppState } from "state/slices/integrated-app-slice";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";

Amplify.configure(authConfig);

export const ACCOUNT_NOT_CONFIRMED = "ACCOUNT_NOT_CONFIRMED";
export const defaultUser: User = {
  signInUserSession: {
    idToken: {
      payload: {
        email: "",
      },
    },
  },
  signedIn: false,
  username: undefined,
  attributes: { sub: "user-id" },
};

const defaultAuth = {
  user: defaultUser,
  getEmail: () => "",
  signIn: ({ username, password }: any) => new Promise(() => ""),
  signOut: () => new Promise(() => null),
  confirmSignUp: (username: string, code: string) => new Promise(() => null),
  resendSignUp: (username: string) => new Promise(() => null),
  googleSignIn: (currentGroupId: string) => new Promise(() => null),
  facebookSignIn: (currentGroupId: string) => new Promise(() => null),
  pending: true,
  signedIn: false,
  amplifyAuth: Auth,
  confirmSmsMfaSignIn: (code: string) => new Promise(() => null),
  verifyPhoneNumber: (code: string) => new Promise(() => null),
  preferredMfa: () => new Promise(() => null),
};

const authContext = createContext(defaultAuth);

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }: any) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext);
};

// Provider hook that creates auth object and handles state
export function useProvideAuth() {
  const [user, setUser] = useState(defaultUser);
  const integratedAppState = useAppSelector(getIntegratedAppState);
  const [pending, setPending] = useState(true);
  const dispatch = useAppDispatch();
  const [groupId, setGroupId] = useState("");

  useEffect(() => {
    if (groupId) {
      const path = getGroupDetailsPath(groupId);
      getRequest(path).then((data) => {
        dispatch(setGroup(data));
      });
    }
  }, [dispatch, groupId]);

  // Implementation below based directly on the examples from AWS docs
  // ref: https://docs.amplify.aws/lib/auth/social/q/platform/js#full-samples

  useEffect(() => {
    Auth.currentSession();
    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "customOAuthState":
          const groupId = decodeURIComponent(data);
          setGroupId(groupId);
          break;
        case "signIn":
        case "cognitoHostedUI":
          setPending(true);
          getUser().then((userData) => {
            setUser(userData);
            setPending(false);
          });
          break;
        case "signOut":
          setUser(defaultUser);
          setPending(false);
          break;
        case "signIn_failure":
        case "cognitoHostedUI_failure":
          // TODO: something went wrong
          setPending(false);
          break;
        default:
      }
    });

    getUser()
      .then((userData) => {
        setUser(userData);
        setPending(false);
      })
      .catch((err) => console.log(err));
  }, []);

  function getUser() {
    return Auth.currentAuthenticatedUser()
      .then((userData) => userData)
      .catch(() => console.log("Not signed in"));
  }

  const signIn = async ({ username, password }: any) => {
    try {
      const returnedUser = await Auth.signIn({ username, password });
      setUser(returnedUser);

      if (returnedUser.challengeName === SMS_MFA) {
        dispatch(showConfirmTwoFactor());
      } else {
        setPending(false);
        dispatch(close());
      }
    } catch (error) {
      const message = (error as any)?.message;

      if (message === "Pending sign-in attempt already in progress") {
        dispatch(showConfirmTwoFactor());
      } else if (message === "User is not confirmed.") {
        return ACCOUNT_NOT_CONFIRMED;
      } else {
        toast.error(message);
        postRequest(getFailedLoginPath(), {
          body: {
            user_email: username,
            meta_data: error,
          },
        });
      }
    }
  };

  const googleSignIn = (currentGroupId: string) => {
    setPending(true);
    return Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Cognito,
      customState: currentGroupId,
    });
  };

  const facebookSignIn = (currentGroupId: string) => {
    setPending(true);
    return Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Facebook,
      customState: currentGroupId,
    });
  };
  const signOut = () => {
    return Auth.signOut().then((res) => {
      setUser(defaultUser);
    });
  };

  const resendSignUp = async (username: string) => {
    return await Auth.resendSignUp(username);
  };

  const getEmail = () => {
    try {
      return user.signInUserSession.idToken.payload.email;
    } catch (error) {
      console.log("Cannot get email from user", user, error);
      return "";
    }
  };

  const confirmSmsMfaSignIn = async (code: string) => {
    const authenticatedUser = await Auth.confirmSignIn(user, code, SMS_MFA);
    setUser(authenticatedUser);
  };

  const verifyPhoneNumber = async (code: string) => {
    await Auth.verifyCurrentUserAttributeSubmit("phone_number", code);
    const user = await Auth.currentAuthenticatedUser();
    const result = await Auth.setPreferredMFA(user, SMS);
    if (result === "SUCCESS") {
      setUser({ ...user, ...{ preferredMFA: SMS_MFA } });
    }
  };

  const confirmSignUp = async (username: string, code: string) => {
    return await Auth.confirmSignUp(username, code);
  };

  const preferredMfa = async () => {
    await Auth.getPreferredMFA(user);
  };

  const state = {
    user,
    getEmail,
    signIn,
    signOut,
    googleSignIn,
    facebookSignIn,
    pending,
    confirmSignUp,
    resendSignUp,
    signedIn:
      !pending &&
      user &&
      user !== defaultUser &&
      user.signInUserSession !== null, // when MFA is enabled but code not yet verified
    amplifyAuth: Auth,
    confirmSmsMfaSignIn,
    verifyPhoneNumber,
    preferredMfa,
  };

  if (integratedAppState.isIntegratedApp && integratedAppState.userId) {
    state.signedIn = true;
  }

  return state;
}
