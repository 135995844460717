import PoolItem from "components/pool-item/pool-item";
import { Pool } from "interfaces/pool";
import styles from "assets/shared-stylesheets/sparket-list-item.module.css";

interface Props {
  pools: Pool[];
}
const ConfirmResultsRepresenter = ({ pools }: Props) => {
  return (
    <div>
      {pools.map((pool) => {
        return (
          <PoolItem
            key={pool.id}
            styles={styles}
            pool={pool}
            link={`/pools/${pool.id}/confirm`}
            showCountDownClock={false}
          />
        );
      })}
    </div>
  );
};

export default ConfirmResultsRepresenter;
