import { useEffect, useState } from "react";
import { formatAsCurrency } from "utils/formatter-utils/currency-formatter";
import { Transaction } from "interfaces/transaction";
import Balance from "./balance";
import BlockyHeavyText from "components/custom-texts/blocky-heavy-text";
import { getRequest } from "utils/httpClient";
import { secondaryColor, sparketBlack } from "utils/constants";
import useWallet from "hooks/useWallet";
import Header from "components/header";
import LoadingSpinner from "components/loading-spinner.tsx";
import Headroom from "react-headroom";
import { Button, Table } from "react-bootstrap";
import styles from "./styles.module.css";
import { getTransactionsForWalletPath } from "utils/backend-path-builders";

const constructTransactionLabel = (transaction: Transaction) => {
  if (!transaction.details) {
    return "Unknown details";
  }

  const {
    re_seed,
    reason,
    contestant,
    pool_name,
    prizeout_redeem,
  } = transaction.details;
  switch (transaction.transaction_type) {
    case "REWARD_JOINED_GROUP":
    case "REWARD_CRON_RESET":
    case "REWARD_CRON_ADD":
    case "REWARD_MANUAL_RESET":
    case "REWARD_MANUAL_ADD":
    case "REWARD_REFERRAL_1ST_BET":
      if (reason) {
        return re_seed + " - " + reason;
      } else {
        return re_seed;
      }
    case "BET_BOOKING":
    case "SEED_BOOKING":
      return `${contestant} - ${pool_name}`;
    case "BET_BOOKING_WW":
      let prefix = "Sold Bet";
      if (transaction.transaction_amount < 0) {
        prefix = "Purchased Bet";
      }
      return `${prefix}: ${contestant} - ${pool_name}`;
    case "BET_REFUND":
      return `Refund Bet: ${pool_name} - ${contestant}`;
    case "POOL_SETTLEMENT":
      return `WIN: ${contestant} - ${pool_name}`;
    case "POOL_FEES":
      return `Collect fees: ${pool_name}`;
    case "PRIZEOUT_REDEEM":
      return `Prizeout Withdrawal: ${prizeout_redeem}`;
    default:
      return "unknown transaction type";
  }
};

function getFormattedDate(dateString: string) {
  const date = new Date(dateString);
  let year = date.getFullYear();
  let month = (1 + date.getMonth()).toString().padStart(2, "0");
  let day = date.getDate().toString().padStart(2, "0");

  return month + "/" + day + "/" + year;
}

const Bankroll = () => {
  const [loading, setLoading] = useState(true);
  const [transactionData, setTransactionData] = useState<Transaction[]>();
  const [page, setPage] = useState(1);
  const { walletId } = useWallet();

  useEffect(() => {
    const queryParams = {
      pg: page,
      page_size: 15,
    };
    if (walletId.length > 0) {
      const path = getTransactionsForWalletPath(walletId);
      getRequest(path, { queryParams, skipIntegrationApi: true })
        .then((data) => {
          setTransactionData(data as Transaction[]);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  }, [walletId, page, loading]);

  const transactions = (transactionData as Transaction[]) || [];
  const sortedTransactions =
    transactions?.sort((a: Transaction, b: Transaction) => {
      return Date.parse(b.created_at) - Date.parse(a.created_at);
    }) || [];

  const isFirstPage = page === 1;

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Headroom>
        <Header />
      </Headroom>

      <div>
        <Balance refreshTransactionData={() => setLoading(true)} />
        <Table striped>
          <thead style={{ borderBottom: "2px solid" }}>
          <tr>
            <th className={styles.bankrollTableHeader}>Transaction</th>
            <th className={styles.bankrollTableHeader}>Date</th>
            <th className={styles.bankrollTableHeader}>Amount</th>
          </tr>
          </thead>
          <tbody>
          {sortedTransactions.map(
            (transaction: Transaction, index: number) => {
              return (
                <tr key={index}>
                  <td>{constructTransactionLabel(transaction)}</td>
                  <td>{getFormattedDate(transaction.created_at)}</td>
                  <td
                    style={{
                      color:
                        transaction.transaction_amount > 0 ? "green" : "red",
                    }}
                  >{`${
                    transaction.transaction_amount > 0 ? "+" : ""
                  }${formatAsCurrency(transaction.transaction_amount)}`}</td>
                </tr>
              );
            }
          )}
          </tbody>
        </Table>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginLeft: 12,
            marginRight: 12,
          }}
        >
          <Button
            style={{
              backgroundColor: secondaryColor,
              border: "none",
              color: sparketBlack,
            }}
            disabled={isFirstPage}
            onClick={() => {
              setLoading(true);
              setPage(page - 1);
            }}
          >
            <BlockyHeavyText>Previous Page</BlockyHeavyText>
          </Button>
          <Button
            style={{
              backgroundColor: secondaryColor,
              border: "none",
              color: sparketBlack,
            }}
            disabled={transactions.length < 12}
            onClick={() => {
              setLoading(true);
              setPage(page + 1);
            }}
          >
            <BlockyHeavyText>Next Page</BlockyHeavyText>
          </Button>
        </div>
      </div>
    </>
  );
};

export default Bankroll;
