import { Mixpanel } from "hooks/mixPanel";
import styles from "./styles.module.css";
import { useAuth } from "hooks/auth";
import { useAppDispatch } from "state/hooks";
import { openPrivacyPolicy, openTermsOfService, } from "state/slices/legal-modal-slice";
import { secondaryColor } from "utils/constants";

const LegalDisclaimer = () => {
  const auth = useAuth();
  const dispatch = useAppDispatch();
  return (
    <div className={styles.disclaimer}>
      By continuing, you agree to the{" "}
      <span
        className={styles.legalLink}
        style={{ color: secondaryColor }}
        onClick={() => {
          Mixpanel.track("Clicked Terms of Service from the Sign In page", {
            isSignedIn: auth.signedIn,
          });
          dispatch(openTermsOfService());
        }}
      >
        Terms of Service
      </span>{" "}
      and the{" "}
      <span
        className={styles.legalLink}
        style={{ color: secondaryColor }}
        onClick={() => {
          Mixpanel.track("Clicked Privacy Policy from the Sign In page", {
            isSignedIn: auth.signedIn,
          });
          dispatch(openPrivacyPolicy());
        }}
      >
        Privacy Policy
      </span>
    </div>
  );
};

export default LegalDisclaimer;
