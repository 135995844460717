import React, { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import BlockyBoldText from "components/custom-texts/blocky-bold-text"
import { getSkillScorePath } from "utils/backend-path-builders";
import { getRequest } from "utils/httpClient";
import { useAppSelector } from "state/hooks";
import { getUserState } from "state/slices/user-slice";
import { QuestionCircle } from "react-bootstrap-icons";
import styles from './styles.module.css';
import { secondaryColor } from "utils/constants";

const SparketScore = () => {

  const user = useAppSelector(getUserState);
  const id = user.id;

  const [score, setScore] = useState<number>(.5);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {

    const fetchScore = async () => {
      try{
        const response = await getRequest(getSkillScorePath(id));
        setScore(response.current_score);
      } catch (error) {
        setScore(.5);
      }
      setIsLoading(false)
    };
    if (id) {
      fetchScore();
    }
  }, [id]);

  const formattedScore = (score * 100).toFixed(2)
  const percentage = `${formattedScore}%`;

  if (isLoading) {
    return null;
  }

  return (
    <div className={styles.sparketScoreContainer}>
      <div className={styles.sparketScoreHeader}>
        <BlockyBoldText style={{ color: 'white' }}>Sparket Skill Score</BlockyBoldText>
        <OverlayTrigger
          placement="right"
          overlay={
            <Tooltip id="tooltip-right">
              <BlockyBoldText style={{ fontSize: 10 }}>Win more to increase your score!</BlockyBoldText>
            </Tooltip>
          }
        >
          <QuestionCircle className={styles.questionCircle} />
        </OverlayTrigger>
      </div>
      <BlockyBoldText style={{ color: secondaryColor }} className={styles.scoreText}>{formattedScore}</BlockyBoldText>
      <div className={styles.scoreBarContainer}>
        <div style={{ width: percentage, backgroundColor: secondaryColor }} className={styles.scoreBar} />
        <div style={{ left: percentage }} className={styles.scoreIndicator}>▲</div>
      </div>
      <div className={styles.scoreRangeContainer}>
        <div>
          <BlockyBoldText className={styles.scoreRangeText}>0</BlockyBoldText>
        </div>
        <div style={{ textAlign: 'right' }}>
          <BlockyBoldText className={styles.scoreRangeText}>100</BlockyBoldText>
        </div>
      </div>
    </div>
  );
};

export default SparketScore;