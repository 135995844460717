import OpenSansText from "components/custom-texts/open-sans-text";
import { sparketDarkRed } from "utils/constants";

interface Props {
  content: string;
  show: boolean;
}
const ErrorMessage = ({ content, show }: Props) => {
  if (!show) {
    return null;
  }

  return (
    <>
      <OpenSansText style={{ color: sparketDarkRed }}>{content}</OpenSansText>
      <br></br>
    </>
  );
};

export default ErrorMessage;
