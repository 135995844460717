import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DEFAULT_AVATAR_SRC, Reward, RewardEnum } from "utils/constants";
import { RootState } from "../store";

interface AvatarModalState {
  loading: boolean;
  selectedCategory: RewardEnum;
  showModal: boolean;
  selectedAvatar: string;
  selectedBadges: string[];
  rewards: Reward[];
}

export const initialState: AvatarModalState = {
  loading: true,
  selectedCategory: RewardEnum.AVATAR,
  showModal: false,
  selectedAvatar: DEFAULT_AVATAR_SRC,
  selectedBadges: [],
  rewards: [],
};

const avatarModalSlice = createSlice({
  name: "avatarModal",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setSelectedCategory: (state, action: PayloadAction<RewardEnum>) => {
      state.selectedCategory = action.payload;
    },
    setShowModal: (state, action: PayloadAction<boolean>) => {
      state.showModal = action.payload;
    },
    setRewards: (state, action: PayloadAction<Reward[]>) => {
      state.rewards = action.payload;
    },
    setSelectedAvatar: (state, action: PayloadAction<string>) => {
      state.selectedAvatar = action.payload;
    },
    setSelectedBadges: (state, action: PayloadAction<string[]>) => {
      state.selectedBadges = action.payload;
    }
  }
});

export const {
  setLoading,
  setSelectedCategory,
  setShowModal,
  setRewards,
  setSelectedBadges,
  setSelectedAvatar
} = avatarModalSlice.actions;

export const getAvatarModalState = (state: RootState) => {
  return state.avatarModal;
};

export default avatarModalSlice.reducer;

