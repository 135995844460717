import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface LoginModalSlice {
  signInOpen: boolean;
  signUpOpen: boolean;
  setupTwoFactorOpen: boolean;
  confirmTwoFactorOpen: boolean;
}

const initialState: LoginModalSlice = {
  signInOpen: false,
  signUpOpen: false,
  setupTwoFactorOpen: false,
  confirmTwoFactorOpen: false,
};

const closeAll = (state: LoginModalSlice) => {
  state.signInOpen = false;
  state.signUpOpen = false;
  state.setupTwoFactorOpen = false;
  state.confirmTwoFactorOpen = false;
};

const slice = createSlice({
  name: "loginModals",
  initialState,
  reducers: {
    showSignIn: (state) => {
      closeAll(state);
      state.signInOpen = true;
    },
    showSignUp: (state) => {
      closeAll(state);
      state.signUpOpen = true;
    },
    showSetupTwoFactor: (state) => {
      closeAll(state);
      state.setupTwoFactorOpen = true;
    },
    showConfirmTwoFactor: (state) => {
      closeAll(state);
      state.confirmTwoFactorOpen = true;
    },
    close: (state) => {
      closeAll(state);
    },
  },
});

export const {
  showSignIn,
  showSignUp,
  showSetupTwoFactor,
  showConfirmTwoFactor,
  close,
} = slice.actions;

export const getLoginModalsState = (state: RootState) => {
  return state.loginModals;
};

export default slice.reducer;
