import BlockyBoldText from "components/custom-texts/blocky-bold-text";
import useWallet from "hooks/useWallet";
import styles from "./styles.module.css";
import BlockyHeavyText from "components/custom-texts/blocky-heavy-text";
import { useAppSelector } from "state/hooks";
import { getGroupStyles } from "state/slices/group-slice";
import PrizeoutButton from "./prizeout-button";
import { textColor } from "utils/constants";

interface Props {
  refreshTransactionData: () => void;
}

const Balance = ({ refreshTransactionData }: Props) => {
  const { formattedBalance, balance } = useWallet();

  const primaryColor = useAppSelector(getGroupStyles).primary_color;
  return (
    <div
      style={{ backgroundColor: primaryColor }}
      className={styles.balanceRow}
    >
      <BlockyHeavyText
        customStyles={{ fontSize: 22, display: "flex", alignItems: "center", color: textColor }}
      >
        Bankroll
      </BlockyHeavyText>
      <div style={{ display: "flex" }}>
        <BlockyBoldText
          customStyles={{
            display: "flex",
            alignItems: "center",
            paddingRight: 7,
            color: textColor,
          }}
        >
          Balance: {formattedBalance}
        </BlockyBoldText>
        <PrizeoutButton
          balance={balance}
          refreshTransactionData={refreshTransactionData}
        />
      </div>
    </div>
  );
};

export default Balance;
