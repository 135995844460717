import { formattedCurrencyToNumber } from "utils/formatter-utils/currency-formatter";
import { Dispatch, SetStateAction } from "react";
import { postRequest } from "utils/httpClient";
import { Bet } from "interfaces/bet";
import { getConfirmBetPath, integratedCreateBet, } from "utils/backend-path-builders";

interface ConfirmBetArguments {
  isIntegratedApp: boolean;
  betAmount: string;
  pool_id: string;
  setLoading: Dispatch<SetStateAction<boolean>>;
  setErrorMessage: Dispatch<SetStateAction<string | undefined>>;
  poolEntryId: string;
  groupId: string;
  onSuccessfulBetPlaced: (bet: Bet) => void;
  currency: string;
  contest_entry_id?: string;
}
const confirmBet = ({
  isIntegratedApp,
  betAmount,
  poolEntryId,
  pool_id,
  setLoading,
  setErrorMessage,
  onSuccessfulBetPlaced,
  groupId,
  currency,
  contest_entry_id,
}: ConfirmBetArguments) => {
  setLoading(true);
  const bet_amount = formattedCurrencyToNumber(betAmount);
  setErrorMessage("");
  const path = isIntegratedApp
    ? integratedCreateBet(pool_id, poolEntryId)
    : getConfirmBetPath(groupId, pool_id, poolEntryId);
  postRequest(path, {
    body: {
      bet_amount,
      currency,
      contest_entry_id,
    },
  })
    .then((data) => {
      if (data.code === 10005) {
        if (data.technical_message !== "") {
          setErrorMessage(data.technical_message);
        } else {
          setErrorMessage(
              "Cannot create transaction. Please check your balance or try again later"
          );
        }
        setLoading(false);
      } else if (data.id === undefined) {
        throw new Error("Something went wrong.");
      } else {
        onSuccessfulBetPlaced(data);
      }
    })
    .catch(
      ({
        response: {
          data: { friendly_error_message },
        },
      }) => {
        setLoading(false);
        setErrorMessage(friendly_error_message)
      }
    );
};
export default confirmBet;
