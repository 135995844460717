import React, { useEffect, useState } from "react";
import { useAppSelector } from "state/hooks";
import { getLatestContestEntryId, validateContestEntryId } from "state/slices/contests-slice";
import BackButton from "./back-button";
import JoinedContestItem from "./joined-contest-item";
import styles from "./styles.module.css";
import BlockyHeavyText from "components/custom-texts/blocky-heavy-text";
import useContestData from "hooks/useContestData";
import { getPoolsPath } from "utils/backend-path-builders";
import { getRequest } from "utils/httpClient";
import { Pool } from "interfaces/pool";
import { getGroupState } from "state/slices/group-slice";
import LoadingSpinner from "components/loading-spinner.tsx";
import { POOL_STATUS } from "utils/constants";


interface Props {
  contestEntryId?: string;
}

const MyContests = ({contestEntryId}: Props) => {

  useContestData();
  const lastContestEntryId: string | undefined = useAppSelector((state) => getLatestContestEntryId(state))
  const isValid: boolean = useAppSelector((state) => validateContestEntryId(state, contestEntryId || ""))
  const validContestEntryId: string | undefined = isValid ? contestEntryId : lastContestEntryId;

  const [pools, setPools] = useState<Pool[]>([]);
  const [loadingPools, setLoadingPools] = useState<boolean>(true);
  const group = useAppSelector(getGroupState);

  useEffect(() => {
    const fetchPools = () => {
      if (!group.id || !validContestEntryId) {
        return;
      }
      const path = getPoolsPath(group.id);
      getRequest(path, {
        skipIntegrationApi: true
      })
        .then((poolsResponse: Pool[]) => {
          const filteredPools = poolsResponse.filter((pool) =>
            [POOL_STATUS.OPEN, POOL_STATUS.LIVE, POOL_STATUS.SETTLED].includes(pool.status)
          );

          const sortedPools = filteredPools.sort((a, b) => {
            if (a.status !== b.status) {
              return a.status - b.status;
            }
            return b.start - a.start;
          });

          setPools(sortedPools);
        })
        .finally(() => {
          setLoadingPools(false);
        });
    };
    fetchPools();
  }, [group.id, validContestEntryId])

  if (loadingPools) {
    return <LoadingSpinner/>
  }

  return (
    <div style={{padding: "20px 20px 0px 20px", height: "calc(100vh - 66px"}}>
      <BackButton backUrl={"/"}/>
      {!!validContestEntryId ? (
        <JoinedContestItem entryId={validContestEntryId} pools={pools}/>
      ) : (
        <div className={styles.noBetsTextContainer}>
          <BlockyHeavyText>You haven't joined any contests yet</BlockyHeavyText>
        </div>
      )}
    </div>
  )
}

export default MyContests;