import BlockyMediumText from "components/custom-texts/blocky-medium-text";
import { useAppDispatch, useAppSelector } from "state/hooks";
import { close } from "state/slices/drawer-slice";
import { includeTermsOfService } from "state/slices/group-slice";
import { openPrivacyPolicy, openTermsOfService, } from "state/slices/legal-modal-slice";
import PoweredBySparket from "./powered-by-sparket";

const Footer = () => {

  const dispatch = useAppDispatch();
  const includeTerms = useAppSelector(includeTermsOfService);

  return (
    <>
      <PoweredBySparket />
      {includeTerms &&
        <div style={{ textAlign: "left" }}>
          <BlockyMediumText
            style={{
              color: "white",
              fontSize: 8,
            }}
          >
            Click to view our{" "}
            <span
              style={{ color: "white", cursor: "pointer", textDecoration: "underline" }}
              onClick={() => {
                dispatch(openTermsOfService());
                dispatch(close());
              }}
            >
            Terms of Service
          </span>
            ,{" "}
            <span
              style={{ color: "white", cursor: "pointer", textDecoration: "underline" }}
              onClick={() => {
                dispatch(openPrivacyPolicy());
                dispatch(close());
              }}
            >
            Privacy Policy
          </span>{" "}
            or to fill out our{" "}
            <span
              style={{ color: "white", cursor: "pointer", textDecoration: "underline" }}
              onClick={() => {
                window.open("https://forms.gle/kzeNASvdQ65wXG8N8", "_blank");
              }}
            >
            Do Not Sell My Personal Information
          </span>{" "}
            form.
          </BlockyMediumText>
        </div>
      }
    </>
  );
};

export default Footer;
