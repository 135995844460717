import styles from "./styles.module.css";
import BlockyHeavyText from "components/custom-texts/blocky-heavy-text";
import { textColor } from "utils/constants";
import MultipleGroupsTooltip from "components/my-bets/multiple-groups-tooltip";
import React from "react";
import { useAppSelector } from "state/hooks";
import { getAvailableGroups } from "state/slices/available-groups-slice";

interface Props {
  selectionType: string;
}

const TitleSection = ({selectionType}: Props) => {
  const availableGroups = useAppSelector(getAvailableGroups);
  const hasOtherGroups = availableGroups.length > 1;

  return (
    <div className={styles.titleRow}>
      <BlockyHeavyText customStyles={{ fontSize: 22, marginRight: 10, color: textColor }}>
        My {selectionType}
      </BlockyHeavyText>
      {hasOtherGroups && <MultipleGroupsTooltip selectionType={selectionType} />}
    </div>
  )
}

export default TitleSection;