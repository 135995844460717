import dateFormatter from "utils/formatter-utils/date-formatter";
import { formatAsCurrency } from "utils/formatter-utils/currency-formatter";
import BlockyHeavyText from "components/custom-texts/blocky-heavy-text";
import BlockyMediumText from "components/custom-texts/blocky-medium-text";
import StatusBadge from "components/status-badge";
import styles from "./styles.module.css";
import { secondaryColor, sparketBlack } from "utils/constants";
import { useState } from "react";
import RulesModal from "./rules-modal";
import Outcome from "./outcome";
import { Button } from "react-bootstrap";
import { BetTypeName } from "interfaces/bet-type-name";
import { useNavigate } from "react-router-dom";

interface PoolSummaryProps {
  pool: any;
  backUrl?: string;
  betType?: BetTypeName;
}

export const HEIGHT_OF_POOL_SUMMARY_SECTION = 250;

const PoolSummary = ({ pool, backUrl = "/", betType }: PoolSummaryProps) => {
  const [showRulesModal, setShowRulesModal] = useState(false);
  const navigate = useNavigate();
  return (
    <>
      <RulesModal
        rules={pool.rules}
        showRulesModal={showRulesModal}
        setShowRulesModal={setShowRulesModal}
      ></RulesModal>
      <div className={styles.poolSummarySection}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Button
            style={{
              backgroundColor: secondaryColor,
              borderColor: secondaryColor,
              color: sparketBlack,
              borderRadius: 5,
            }}
            onClick={() => navigate(backUrl ?? "/")}
          >
            <BlockyHeavyText customStyles={{ fontSize: 14 }}>
              &lt; Back
            </BlockyHeavyText>
          </Button>
          {betType &&
            <div style={{ margin: 8 }}>
              <BlockyHeavyText className={styles.activeBetsText}>
                Pool Size:&nbsp;
              </BlockyHeavyText>
              <BlockyHeavyText className={styles.totalText}>
                {formatAsCurrency(pool["total_" + betType])}
              </BlockyHeavyText>
            </div>
          }
          <div style={{ alignSelf: "center" }}>
            <StatusBadge status={pool.status} is_bet={false} />
          </div>
        </div>
        <BlockyHeavyText
          className={styles.titleText}
          style={{ color: "var(--secondary-color)", fontSize: 22 }}
        >
          {pool.event_name && pool.event_name + ": "}
          {pool.name}
        </BlockyHeavyText>
        <BlockyMediumText className={styles.poolStartText}>
          Closes: {dateFormatter(pool.close)}
        </BlockyMediumText>
        <div className={styles.winConditionContainer}>
          <BlockyMediumText className={styles.winConditionText}>
            Win Condition: {pool.win_condition}
          </BlockyMediumText>
        </div>
        <div
          style={{ color: "var(--secondary-color)" }}
          onClick={() => {
            setShowRulesModal(true);
          }}
        >
          <BlockyMediumText
            style={{ cursor: "pointer", textDecoration: "underline" }}
          >
            See Full Rules
          </BlockyMediumText>
        </div>
        <Outcome pool={pool} />
      </div>
    </>
  );
};

export default PoolSummary;
