import { secondaryColor, sparketBlack } from "utils/constants";
import BlockyHeavyText from "components/custom-texts/blocky-heavy-text";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

interface Props {
  backUrl: string;
}

const BackButton = ({ backUrl }: Props) => {
  const navigate = useNavigate();
  return (
    <Button
      style={{
        backgroundColor: secondaryColor,
        borderColor: secondaryColor,
        color: sparketBlack,
        borderRadius: 5,
        marginLeft: 20,
      }}
      onClick={() => navigate(backUrl)}
    >
      <BlockyHeavyText customStyles={{ fontSize: 14 }}>
        &lt; Back
      </BlockyHeavyText>
    </Button>
  );
};

export default BackButton;