import BlockyMediumText from "components/custom-texts/blocky-medium-text";
import useContestData from "hooks/useContestData";
import { Button, Modal } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "state/hooks";
import { getGroupState } from "state/slices/group-slice";
import { openContestTerms } from "state/slices/legal-modal-slice";
import { getEnterContestPath } from "utils/backend-path-builders";
import { postRequest } from "utils/httpClient";
import styles from "./styles.module.scss";
import { sparketGold } from "utils/constants";

const EnterContestModal = ({ showModal, setShowModal, contest }: any) => {
  const { refetchUserContests } = useContestData();
  const dispatch = useAppDispatch();
  const {
    settings: { secondary_color },
  } = useAppSelector(getGroupState);

  const handleJoinContest = () => {
    const path = getEnterContestPath();
    const body = {
      body: {
        contest_id: contest.id,
        status: "joined",
      },
    };
    try {
      postRequest(path, body);
      refetchUserContests();
    } catch (error) {
      console.error("Error joining contest", error);
    }
    setShowModal(false);
  };

  const entryFee =
    contest.entry_fee && contest.entry_fee > 0
      ? "$" + contest.entry_fee
      : "Free";

  return (
    <Modal onHide={() => setShowModal(false)} show={showModal}>
      <Modal.Body>
        <div style={{ textAlign: "center" }}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <BlockyMediumText>
              You are entering the contest for an entry fee of{" "}
              <strong>{entryFee}</strong>
            </BlockyMediumText>
          </div>

          <a href="#" onClick={() => dispatch(openContestTerms())}>
            contest terms link
          </a>

          <div className={styles.buttonWrapper}>
            <Button
              size="sm"
              style={{
                background: secondary_color || sparketGold,
                color: "black",
                border: "none",
              }}
              onClick={() => handleJoinContest()}
            >
              <BlockyMediumText>Join</BlockyMediumText>
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default EnterContestModal;
