import React, { useState } from "react";
import { Order } from "interfaces/commerce/order/order";
import OrdersModal from "components/profile/orders/orders-modal";
import { Spinner } from "react-bootstrap";
import { hasActive } from "payment-provider/inovio/inovio";

interface OrdersButtonProps {
  orders: Order[];
}

const OrdersButton: React.FC<OrdersButtonProps> = ({ orders }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <div>
      <div className="d-flex align-items-center justify-content-center m-2 position-relative">
        <div
          style={{
            color: "white",
            background: "transparent",
            cursor: "pointer",
            border: "none",
            fontSize: 18,
            fontStyle: "italic",
            textDecoration: "underline",
          }}
          onClick={() => setShowModal(true)}
        >
          My Orders
        </div>
        {hasActive(orders) && (
          <Spinner
            animation="border"
            style={{
              borderWidth: "2px",
              color: "white",
              width: "20px",
              height: "20px",
              marginLeft: "3rem",
              position: "absolute",
              right: "-25px",
            }}
          />
        )}
      </div>
      <OrdersModal show={showModal} onHide={() => setShowModal(false)} orders={orders} />
    </div>
  );
};

export default OrdersButton;
