import { useAppDispatch, useAppSelector } from "state/hooks";
import { close as closeLegalModals, getLegalModalState, } from "state/slices/legal-modal-slice";
import LegalModal from "./legal-modal";
import { close as closeDrawer } from "state/slices/drawer-slice";
import { getGroupState } from "state/slices/group-slice";
import { getPrivacyPolicyPath, getTermsOfServicePath, } from "utils/backend-path-builders";
import { CONTEST_TERMS_HEADER } from "utils/constants";

const LegalModals = () => {
  const {
    termsOfServiceOpen,
    privacyPolicyOpen,
    contestTermsOpen,
  } = useAppSelector(getLegalModalState);

  const contestTermsUrl = useAppSelector(getGroupState).settings
    .contest_terms_url;

  const dispatch = useAppDispatch();

  const hideModal = () => {
    dispatch(closeLegalModals());
    dispatch(closeDrawer());
  };

  const legalModalsProps = [
    {
      show: termsOfServiceOpen,
      header: "Terms of Service",
      endPoint: process.env.REACT_APP_BACKEND_URL + getTermsOfServicePath(),
    },
    {
      show: privacyPolicyOpen,
      header: "Privacy Policy",
      endPoint: process.env.REACT_APP_BACKEND_URL + getPrivacyPolicyPath(),
    },
    {
      show: contestTermsOpen,
      header: CONTEST_TERMS_HEADER,
      endPoint: contestTermsUrl,
    },
  ];

  return (
    <>
      {legalModalsProps.map((props) => {
        return (
          <LegalModal key={props.header} hideModal={hideModal} {...props} />
        );
      })}
    </>
  );
};

export default LegalModals;
