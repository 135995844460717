import BlockyHeavyText from "components/custom-texts/blocky-heavy-text";
import { Button, Fade } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "state/hooks";
import { getGroupState, requestToJoinGroup, setUserGroupMembershipStatus } from "state/slices/group-slice";
import { useEffect, useRef, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import { Checkmark } from "react-checkmark";
import styles from "./styles.module.css";
import { fetchAvailableGroups } from "state/slices/available-groups-slice";
import { getRequest } from "utils/httpClient";
import JoinGroupModal from "./join-group-modal";
import { openExternalIdModal } from "state/slices/external-id-modal-slice";
import { getGetUserGroupMembershipStatusPath } from "utils/backend-path-builders";
import PendingIdentityVerificationTooltip from "./pending-identity-verification-tooltip";
import { local as localStorage } from "utils/local-storage";
import { blockContestModal, openContestPopup, } from "state/slices/contest-popup-modal-slice";
import { SEEN_CONTEST_POPUP_URLS } from "components/contest-popup/modal";
import { getIntegratedAppState } from "state/slices/integrated-app-slice";
import { useNavigate } from "react-router-dom";
import { UserGroupMembershipStatus } from "utils/userGroupMembershipStatus";

interface ButtonTextProps {
  userGroupMembershipStatus: UserGroupMembershipStatus;
}

const ButtonText = ({ userGroupMembershipStatus }: ButtonTextProps) => {

  let text: string;
  let fontStyle = "initial";

  const identityVerificationIsPending = () => userGroupMembershipStatus === UserGroupMembershipStatus.PENDING_IDENTITY_VERIFICATION;

  if (userGroupMembershipStatus === UserGroupMembershipStatus.PENDING) {
    text = "Pending";
    fontStyle = "italic";
  } else if (identityVerificationIsPending()) {
    text = "Pending Identity Verification";
    fontStyle = "italic";
  } else {
    text = "Join Group";
  }

  return (
    <BlockyHeavyText style={{ fontStyle: fontStyle, color: "black" }}>
      &nbsp;&nbsp; {text} &nbsp;&nbsp;
      {identityVerificationIsPending() && <PendingIdentityVerificationTooltip />}
    </BlockyHeavyText>
  );
};

const Container = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const group = useAppSelector(getGroupState);
  const { enable_contest_popup, contest_popup_url } = group.settings;
  const haventSeenContestYet =
    contest_popup_url &&
    !localStorage.getItem(SEEN_CONTEST_POPUP_URLS)?.includes(contest_popup_url);

  const [loading, setLoading] = useState(false);

  const [showWelcomeMessage, setShowWelcomeMessage] = useState(false);
  const [showJoinGroupModal, setShowJoinGroupModal] = useState(false);
  const isIntegration = useAppSelector(getIntegratedAppState).isIntegratedApp;
  const initialGroupMembershipStatusRef = useRef<UserGroupMembershipStatus>();

  useEffect(() => {
    if (group.settings.external_id_label && group.status !== UserGroupMembershipStatus.APPROVED) {
      // this will prevent the contest modal from appearing if the
      // external id modal is about to appear also.
      dispatch(blockContestModal(true));
    }
  }, [dispatch, group.settings.external_id_label, group.status]);

  // when group is changed fetch group status
  useEffect(() => {
    setLoading(true);
    const path = getGetUserGroupMembershipStatusPath(group.id);
    getRequest(path, {
      skipIntegrationApi: true,
      responseType: "text",
      queryParams: {
        "referrer-id": localStorage.getItem("referrer-id-" + group.id) || "",
        medium: localStorage.getItem("referrer-medium-" + group.id) || "",
      },
    }).then((status: UserGroupMembershipStatus) => {
      dispatch(setUserGroupMembershipStatus(status));
      setLoading(false);
    });
  }, [dispatch, isIntegration, group.id]);

  useEffect(() => {
    const curStatus = group.status;

    if (!initialGroupMembershipStatusRef.current && group.status) {
      initialGroupMembershipStatusRef.current = group.status;
    }
    if (curStatus === UserGroupMembershipStatus.APPROVED &&
      (initialGroupMembershipStatusRef.current && initialGroupMembershipStatusRef.current !== UserGroupMembershipStatus.APPROVED) &&
      !isIntegration) {
      setLoading(false);
      dispatch(fetchAvailableGroups());
      setTimeout(() => {
        setShowWelcomeMessage(true);
        if (group.settings.external_id_label) {
          setTimeout(() => {
            setShowWelcomeMessage(false);
            dispatch(openExternalIdModal());
            dispatch(blockContestModal(false));
          }, 3000);
        } else {
          if (enable_contest_popup && haventSeenContestYet) {
            dispatch(openContestPopup());
          }
        }
      }, 3000);
    }
  }, [dispatch, isIntegration, group.status, group.settings.external_id_label, haventSeenContestYet, enable_contest_popup]);

  if (showWelcomeMessage) {
    return (
      <div
        style={{
          color: "white",
          backgroundColor: "var(--sparket-dark-green)",
          borderRadius: 20,
          height: 31,
          paddingLeft: 10,
          paddingRight: 10,
          display: "flex",
          alignItems: "center",
        }}
      >
        <BlockyHeavyText>Welcome to {group.name}</BlockyHeavyText>
      </div>
    );
  }

  return <>
    {group.status && <div>
      <Fade
        in={
          group.status !== UserGroupMembershipStatus.APPROVED ||
          (initialGroupMembershipStatusRef.current &&
            initialGroupMembershipStatusRef.current !== UserGroupMembershipStatus.APPROVED)
        }
        unmountOnExit
      >
        <div>
          <Button
            className={styles.button}
            disabled={group.status === UserGroupMembershipStatus.PENDING}
            onClick={() => {
              switch (group.status) {
                case UserGroupMembershipStatus.NOT_REQUESTED:
                  setShowJoinGroupModal(true);
                  break;
                case UserGroupMembershipStatus.PENDING_IDENTITY_VERIFICATION:
                  navigate("/edit-profile");
                  break;
              }
            }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              {
                loading ?
                  <Spinner animation="border" style={{ height: 25, width: 25 }} /> :
                  <>
                    {group.status === UserGroupMembershipStatus.APPROVED ? (
                      <Checkmark size="medium" color="black" />
                    ) : (
                      <ButtonText userGroupMembershipStatus={group.status} />
                    )}
                  </>
              }
            </div>
          </Button>
        </div>
      </Fade>
      <JoinGroupModal
        acceptAndJoin={() => {
          setLoading(true);
          dispatch(requestToJoinGroup(group.id));
          setShowJoinGroupModal(false);
        }}
        show={showJoinGroupModal}
        close={() => setShowJoinGroupModal(false)}
      />
    </div>
    }

  </>;
};

export default Container;
