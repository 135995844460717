import React from "react";
import ContestItem from "home/contest-card/contest-item";
import { useAppSelector } from "state/hooks";
import { getContestById, getContestIdByEntryId } from "state/slices/contests-slice";
import MyBets from "components/my-bets/my-bets";
import TitleSection from "./title-section";
import PoolList from "./pool-list";
import { Pool } from "interfaces/pool";

interface Props {
  entryId: string;
  pools: Pool[];
}

const JoinedContestItem = ({entryId, pools}: Props) => {
  const contestId = useAppSelector(
    (state) => getContestIdByEntryId(state, entryId)
  ) || "";

  const contest = useAppSelector((state) => getContestById(state, contestId));

  const CONTEST_TEXT= "Contests"

  const filteredPools = pools.filter(p => p.contest_id === contestId)

  return (
    <div style={{ display: "flex", flexGrow: 1, height: "calc(100vh - 121px" }}>
      <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
        <TitleSection selectionType={CONTEST_TEXT} />
        <ContestItem contest={contest} />
        <div style={{ flexGrow: 1, overflowY: "auto" }}>
          <PoolList pools={filteredPools} contestEntryId={entryId} />
        </div>
      </div>
      <div style={{ flex: 1, overflowY: "auto" }}>
        <MyBets entryId={entryId} isContest={true} />
      </div>
    </div>
  )
}

export default JoinedContestItem