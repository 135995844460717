import OpenSansText from "components/custom-texts/open-sans-text";

const XpointNotRunningText = () => {
  return (
    <>
      <br></br>
      <OpenSansText>
        To verify your location, please download and run the{" "}
        <a target="_blank" href="https://install.xpoint.tech/">
          xpoint app
        </a>{" "}
        for your device, and then click retry. Xpoint is required for regulatory
        purposes and will only be used for location verification.
      </OpenSansText>
      <br></br>
      <OpenSansText>
        If you already have xpoint installed{" "}
        <a href="xpoint://launch">click here</a> to launch.
      </OpenSansText>
    </>
  );
};

export default XpointNotRunningText;
