import { useEffect, useState } from "react";
import { Spinner, Table } from "react-bootstrap";
import { getRequest } from "utils/httpClient";
import countries from "i18n-iso-countries";
import { getUsersPersonalInformationPath } from "utils/backend-path-builders";
import "assets/shared-stylesheets/transparent-table.module.css";

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

export interface User {
  first_name: string;
  middle_name: string;
  middle_name_initial: string;
  last_name: string;
  street: string;
  street_2: string;
  city: string;
  country: string;
  postal_code: string;
  date_of_birth: string;
  phone_number: string;
  gender: string;
  email: string;
  id: string;
}

const ProfileInfo = (props: any) => {
  const [user, setUser] = useState<User>({} as User);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getRequest(getUsersPersonalInformationPath())
      .then((data) => {
        setUser(data);
      })
      .catch((err) => {
        console.warn(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          height: 90,
        }}
      >
        <Spinner animation="border" variant="warning" />
      </div>
    );
  }

  return (
    <div className={"p-4 w-100"}>
      <Table size="sm">
        <tbody>
      <tr>
        <td>First Name</td>
        <td>{user.first_name}</td>
      </tr>
      <tr>
        <td>Last Name</td>
        <td>{user.last_name}</td>
      </tr>
      <tr>
        <td>Date of Birth</td>
        <td>{user.date_of_birth}</td>
      </tr>
      <tr>
        <td>Street Address</td>
        <td>{user.street}</td>
      </tr>
      <tr>
        <td>City</td>
        <td>{user.city}</td>
      </tr>
      <tr>
        <td>Postal Code</td>
        <td>{user.postal_code}</td>
      </tr>
        <tr>
          <td>Country</td>
          <td>{countries.getName(user.country, "en")}</td>
        </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default ProfileInfo;
