import BlockyHeavyText from "components/custom-texts/blocky-heavy-text";
import LoadingSpinner from "components/loading-spinner.tsx";
import { Bet } from "interfaces/bet";
import { useEffect, useState } from "react";
import { getRequest } from "utils/httpClient";
import MyBetItem from "./my-bet-item";
import styles from "./styles.module.css";
import { useAppSelector } from "state/hooks";
import { getGroupState } from "state/slices/group-slice";
import {
  getBetDetailsPath,
  getMyBetsPath,
  integratedGetBetDetails,
  integratedMyBets,
} from "utils/backend-path-builders";
import Modal from "./modal";
import { getIntegratedAppState } from "state/slices/integrated-app-slice";
import TitleSection from "components/my-contests/title-section";
import { useLocation, useParams } from "react-router-dom";

export interface MyBetsProps {
  entryId?: string;
  isContest?: boolean;
}

const MyBets = (props: MyBetsProps) => {
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const [bets, setBets] = useState([]);
  const [loading, setLoading] = useState(true);
  const group = useAppSelector(getGroupState);
  const [selectedBet, setSelectedBet] = useState<Bet | undefined>();
  const isIntegratedApp = useAppSelector(getIntegratedAppState).isIntegratedApp;
  const entryId = props?.entryId;
  const PICKS_TEXT = "Picks";

  useEffect(() => {
    if (location.state && location.state.bet) {
      setSelectedBet(location.state.bet);
      setLoading(false);
    } else if (id) {
      const path = isIntegratedApp
        ? integratedGetBetDetails(id)
        : getBetDetailsPath(group.id, id);
      getRequest(path).then((data) => {
        setSelectedBet(data);
        setLoading(false);
      });
    }
  }, [id, location.state, isIntegratedApp, group.id]);
  useEffect(() => {
    if (!group.id) {
      return;
    }
    const path = isIntegratedApp ? integratedMyBets() : getMyBetsPath(group.id);
    const queryParams = {
      queryParams: entryId && !isIntegratedApp ? { entryId: entryId } : {}
    };

    getRequest(path, queryParams).then((data) => {
      if (data.length >= 1) {
        const sortedBets = data.sort((a: Bet, b: Bet) => b.created - a.created);
        setBets(sortedBets);
      }
      setLoading(false);
    });
  }, [group.id, entryId, isIntegratedApp]);

  if (loading) {
    return <LoadingSpinner />;
  }

  const hasBets = bets !== null && bets.length >= 1;
  const isContest = props?.isContest;

  return (
    <>
      {(hasBets || isContest) && <TitleSection selectionType={PICKS_TEXT} />}
      {hasBets ? (
        <>
          {bets.map((bet, index) => {
            return (
              <MyBetItem
                setSelectedBet={setSelectedBet}
                key={index}
                bet={bet}
              />
            );
          })}
        </>
      ) : (
        <div className={styles.noBetsTextContainer}>
          <BlockyHeavyText>You haven't made any picks yet</BlockyHeavyText>
        </div>
      )}
      <Modal bet={selectedBet} hide={() => setSelectedBet(undefined)} />
    </>
  );
};

export default MyBets;
