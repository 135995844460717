import React, { useEffect, useState } from "react";
import Select from "react-select";
import { getRequest } from "utils/httpClient";
import { useAuth } from "hooks/auth";
import { useAppDispatch, useAppSelector } from "state/hooks";
import { getGroupState, getGroupStatus, requestToJoinGroup, } from "state/slices/group-slice";
import PoolItem from "components/pool-item/pool-item";
import styles from "assets/shared-stylesheets/sparket-list-item.module.css";
import Header from "components/header";
import LoadingSpinner from "components/loading-spinner.tsx";
import Headroom from "react-headroom";
import { Pool } from "interfaces/pool";
import BlockyHeavyText from "components/custom-texts/blocky-heavy-text";
import "react-bootstrap-typeahead/css/Typeahead.css";
import useDeviceDimensions from "hooks/useDeviceDimensions";
import JoinGroupButton from "./join-group-button";
import SetGroupStateFromSlug from "app-setup/set-group-state-from-slug";
import SetupMixpanel from "app-setup/mixpanel-init";
import LoginButtons from "components/login-buttons";
import { getPoolsPath } from "utils/backend-path-builders";
import { POOL_STATUS_PARAM, SUCCEEDED, USERNAME_CUSTOMISATION_POPUP_LIST, } from "utils/constants";
import { getUserState } from "state/slices/user-slice";
import UsernameForm from "./username-form";
import { local as localStorage } from "utils/local-storage";
import JoinGroupModal from "./join-group-button/join-group-modal";
import { SparketsBanner, ViewMarketplaceButton, } from "@wagerwire/embed/react";
import InitializeIntegrationApp from "app-setup/initialize-integration-app";
import { getIntegratedAppState } from "state/slices/integrated-app-slice";
import LoadingGroupPage from "components/loading-group-page";
import { UserGroupMembershipStatus } from "utils/userGroupMembershipStatus";
import {
  clearHomePageEventIdFilter,
  getUserGroupSettingsByGroupId,
  setHomePageEventIdFilter
} from "state/slices/user-settings-slice";
import useQueryParams from "utils/useQueryParams";
import { EVENT_ID_QUERY_PARAM } from "home/home-page-query-parameters";
import { useParams } from "react-router-dom";
import ContestCard from "./contest-card";
import { useTrackEvent } from "hooks/useTrackEvent";

const EVENT_ID_QUERY_PARAM_CLEAR_EVENT_FILTER_REQUEST_VALUE = "";

const Home = () => {
  const { groupSlug } = useParams<{ groupSlug: string }>();

  const auth = useAuth();
  const queryParams = useQueryParams();

  const { isMobile } = useDeviceDimensions();
  const [eventFilter, setEventFilter] = useState<EventIdAndName>();
  const [pools, setPools] = useState<Pool[]>([]);
  const [poolsLoaded, setPoolsLoaded] = useState(false);
  const [loadingPools, setLoadingPools] = useState(true);
  const [filteredPools, setFilteredPools] = useState<Pool[]>([]);
  const group = useAppSelector(getGroupState);
  const [uniqueEvents, setUniqueEvents] = useState<EventIdAndName[]>([]);
  const isIntegratedApp = useAppSelector(getIntegratedAppState).isIntegratedApp;
  const [showUsernameModal, setShowUsernameModal] = useState(false);
  const [showJoinGroupModal, setShowJoinGroupModal] = useState(false);
  const dispatch = useAppDispatch();
  const groupStatus = useAppSelector(getGroupStatus);
  const userGroupSettings = useAppSelector(state => getUserGroupSettingsByGroupId(state, group.id));
  const user = useAppSelector(getUserState);

  SetupMixpanel();

  SetGroupStateFromSlug(groupSlug);

  InitializeIntegrationApp(groupSlug);

  useEffect(() => {
    const updateEventIdFilterUserSettings = () => {
      if (!group.id) {
        return;
      }
      const eventIdQueryParam = queryParams.get(EVENT_ID_QUERY_PARAM);

      if (eventIdQueryParam === null) return;

      if (eventIdQueryParam === EVENT_ID_QUERY_PARAM_CLEAR_EVENT_FILTER_REQUEST_VALUE) {
        dispatch(clearHomePageEventIdFilter({ groupId: group.id }));
      } else {
        dispatch(setHomePageEventIdFilter({ groupId: group.id, eventId: eventIdQueryParam }));
      }
    };

    updateEventIdFilterUserSettings();
  }, [queryParams, dispatch, group.id]);

  useEffect(() => {
    const syncUserSettingsAndEventFilter = () => {
      if (!poolsLoaded) {
        return;
      }

      const eventFilter = uniqueEvents.find(event => event.id === userGroupSettings?.homePagePoolsEventIdFilter);

      if (!eventFilter) {
        dispatch(clearHomePageEventIdFilter({ groupId: group.id }));
        setEventFilter(undefined);
      } else {
        setEventFilter(eventFilter);
      }
    };

    syncUserSettingsAndEventFilter();
  }, [dispatch, userGroupSettings?.homePagePoolsEventIdFilter, uniqueEvents, poolsLoaded, isIntegratedApp, group.id]);

  useEffect(() => {
    const fetchPools = () => {
      if (!group.id) {
        return;
      }
      const path = getPoolsPath(group.id);
      getRequest(path, {
        queryParams: {
          status: POOL_STATUS_PARAM.OPEN,
          pg: 1,
          page_size: 10000,
          sortBy: "weight:desc,close:asc"
        },
        skipIntegrationApi: true
      })
        .then((poolsResponse: Pool[]) => {
          setPools(poolsResponse);

          const eventIdAndNameByEventId = new Map(
            poolsResponse
              .filter(p => p.event_name && p.event_id)
              .map(p => [p.event_id, { id: p.event_id, name: p.event_name } as EventIdAndName])
          );

          const uniqueEvents = Array.from(eventIdAndNameByEventId.values());

          setUniqueEvents(uniqueEvents);
        })
        .finally(() => {
          setPoolsLoaded(true);
          setLoadingPools(false);
        });
    };

    fetchPools();
  }, [group.id]);

  useEffect(() => {
    const filterPools = () => {
      if (!eventFilter) {
        setFilteredPools(pools);
      } else {
        setFilteredPools(pools.filter((pool) => pool.event_id === eventFilter.id));
      }
    };

    filterPools();
  }, [pools, eventFilter]);

  useEffect(() => {
    if (!auth.signedIn || isIntegratedApp) {
      return;
    }

    const username = user.user_name;

    const sparketGeneratedUsername =
      username.substr(0, 6) === "player" && !isNaN(Number(username.substr(6)));

    const alreadyAskedUsernames =
      localStorage.getItem(USERNAME_CUSTOMISATION_POPUP_LIST)?.split(",") || [];
    if (sparketGeneratedUsername && !alreadyAskedUsernames.includes(username)) {
      setShowUsernameModal(true);
    }
  }, [isIntegratedApp, auth.signedIn, user.user_name]);

  const trackEvent = useTrackEvent(group.id, 'page_view');

  useEffect(() => {
    trackEvent();
  }, [group.id])

  if (!group.id) {
    return <LoadingGroupPage />;
  }

  if (
    loadingPools ||
    (!isIntegratedApp &&
      (auth.pending || (auth.signedIn && user.fetchUserState !== SUCCEEDED)))
  ) {
    return <LoadingSpinner />;
  } else {
    return (
      <>
        <Headroom>
          <div>
            <Header />
            <LoginButtons />
          </div>
        </Headroom>
          {/* using local storage as a feature flag for testing

         localStorage.setItem('skill-contest', 'true')
        */}
        {localStorage.getItem("skill-contest") === "true" && <ContestCard />}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingLeft: 20,
            paddingRight: 20,
            flexWrap: "wrap-reverse",
          }}
        >
          {group.settings.wager_wire && (
            <ViewMarketplaceButton
              groupColor={group.settings.primary_color}
              style={{ width: "100%", padding: 0 }}
              groupId={group.id}
            >
              <SparketsBanner groupColor={group.settings.secondary_color} />
            </ViewMarketplaceButton>
          )}
          {pools.length > 0 && (
            <div
              style={{
                width: isMobile ? "100%" : "50%",
                paddingTop: 10,
                paddingBottom: 5,
              }}
            >
              <Select
                isClearable
                placeholder={"Filter by event"}
                options={Array.from(uniqueEvents).map((event: EventIdAndName) => ({
                  value: event.id,
                  label: event.name
                }))}
                value={eventFilter ? { value: eventFilter?.id, label: eventFilter?.name } : null}
                onChange={(selectedEvent) => {
                  if (selectedEvent == null) {
                    dispatch(clearHomePageEventIdFilter({ groupId: group.id }));
                  } else {
                    dispatch(setHomePageEventIdFilter({ groupId: group.id, eventId: selectedEvent.value }));
                  }
                }}
              />
            </div>
          )}
          {auth.signedIn && (
            <div
              style={{
                paddingTop: 10,
                paddingBottom: 5,
                marginLeft: "auto",
              }}
            >
              {!isIntegratedApp && <JoinGroupButton />}
            </div>
          )}
        </div>
        {filteredPools.length > 0 ? (
          <>
            {filteredPools.map((pool) => {
              return (
                <PoolItem
                  key={pool.id}
                  styles={styles}
                  pool={pool}
                  link={`/pools/${pool.id}`}
                  showCountDownClock={true}
                />
              );
            })}
          </>
        ) : (
          <BlockyHeavyText
            customStyles={{
              display: "flex",
              justifyContent: "center",
              color: "white",
            }}
          >
            No pools are currently open. Please check back later.
          </BlockyHeavyText>
        )}
        {auth.signedIn && !isIntegratedApp && (
          <UsernameForm
            close={() => {
              const alreadyAskedUsernames =
                localStorage
                  .getItem(USERNAME_CUSTOMISATION_POPUP_LIST)
                  ?.split(",") || [];
              alreadyAskedUsernames.push(user.user_name);

              localStorage.setItem(
                USERNAME_CUSTOMISATION_POPUP_LIST,
                alreadyAskedUsernames.join(",")
              );

              if (groupStatus === UserGroupMembershipStatus.NOT_REQUESTED) {
                setShowJoinGroupModal(true);
              }

              setShowUsernameModal(false);
            }}
            show={showUsernameModal}
          />
        )}

        <JoinGroupModal
          acceptAndJoin={() => {
            dispatch(requestToJoinGroup(group.id));
            setShowJoinGroupModal(false);
          }}
          show={showJoinGroupModal}
          close={() => setShowJoinGroupModal(false)}
        />
      </>
    );
  }
};

export default Home;

interface EventIdAndName {
  id: string;
  name: string;
}