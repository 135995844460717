import { Button, Spinner } from "react-bootstrap";
import BlockyHeavyText from "components/custom-texts/blocky-heavy-text";
import styles from "./modal-styles.module.css";

interface Props {
  loading: boolean;
  onClick: () => void;
}
const RetryButton = ({ loading, onClick }: Props) => {
  return (
    <Button className={styles.retryButton} disabled={loading} onClick={onClick}>
      {loading ? (
        <Spinner animation="border" />
      ) : (
        <BlockyHeavyText style={{ color: "black" }}>Retry</BlockyHeavyText>
      )}
    </Button>
  );
};

export default RetryButton;
