import { useAppDispatch, useAppSelector } from "state/hooks";
import { useEffect } from "react";
import { useAuth } from "hooks/auth";
import { fetchUser, getUserState } from "state/slices/user-slice";
import { IDLE } from "utils/constants";

const InitializeUserState = () => {
  const dispatch = useAppDispatch();
  const auth = useAuth();
  const user = useAppSelector(getUserState);

  useEffect(() => {
    if (auth.signedIn && user.fetchUserState === IDLE) {
      dispatch(fetchUser());
    }
  }, [dispatch, auth.signedIn, user.fetchUserState]);
};

export default InitializeUserState;
