import { Alert } from "react-bootstrap";
import { iso8601DateTimeStringToFormatted } from "utils/formatter-utils/date-formatter";

import Limitation from "interfaces/limitation";

interface Props {
  exclusion: Limitation;
}

const SelfExclusionDisclaimer = ({ exclusion }: Props) => {
  return (
    <Alert style={{ maxWidth: 500 }} variant="danger">
      You have excluded yourself from all wagering activity from
      <span style={{ fontStyle: "italic" }}>
        {" "}
        {iso8601DateTimeStringToFormatted(exclusion.start_date)}{" "}
      </span>
      through{" "}
      <span style={{ fontStyle: "italic" }}>
        {iso8601DateTimeStringToFormatted(exclusion.end_date)}
      </span>
      . If you have any questions please contact support@betsparket.com.
    </Alert>
  );
};

export default SelfExclusionDisclaimer;
