import { ReactComponent as Verified } from "icons/identity-verification-status/verified.svg";
import { ReactComponent as Error } from "icons/identity-verification-status/error.svg";
import { IDENTITY_VERIFICATION_STATUS } from "interfaces/users-response";

const getVerificationStatusIcon = (verificationStatus: string) => {
  switch (verificationStatus) {
    case IDENTITY_VERIFICATION_STATUS.APPROVED:
      return <Verified data-testid="verified-icon" />;
    case IDENTITY_VERIFICATION_STATUS.REJECTED:
    case IDENTITY_VERIFICATION_STATUS.DUPLICATED:
    case IDENTITY_VERIFICATION_STATUS.MINIMAL_AGE:
      return <Error data-testid="error-icon" />;
    case IDENTITY_VERIFICATION_STATUS.NOT_STARTED:
      return null;
    default:
      return null;
  }
};

export default getVerificationStatusIcon;
