import { Contest, ContestEntry, JoinedContest } from "interfaces/leaderboard/contest";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "state/store";
import { getContestsPath, getJoinedContestsPath, } from "utils/backend-path-builders";
import { getRequest } from "utils/httpClient";

export interface ContestsState {
  contests: Contest[];
  joinedContests: JoinedContest[];
  loading: boolean;
  loadingJoinedContests: boolean;
  error: string | null;
  contestIdToEntryId: Record<string, string[]>;
  entryIdToContestId: Record<string, string>;
}

export const initialState: ContestsState = {
  contests: [],
  joinedContests: [],
  loading: false,
  loadingJoinedContests: false,
  error: null,
  contestIdToEntryId: {},
  entryIdToContestId: {},
};

export const fetchContests = createAsyncThunk<
  Contest[],
  string,
  { rejectValue: string }
>("contests/fetchContests", async (groupId, { rejectWithValue }) => {
  const contestsPath = getContestsPath(groupId);

  try {
    const response = await getRequest(contestsPath, {
      queryParams: {
        active: true,
      },
      skipIntegrationApi: true,
    });

    return response;
  } catch (error) {
    console.error(`Error fetching contests`, error);
    return rejectWithValue("Failed to fetch contests");
  }
});

export const fetchUserContests = createAsyncThunk<
  JoinedContest[],
  string,
  { rejectValue: string }
>("contests/fetchUserContests", async (groupId, { rejectWithValue }) => {
  const path = getJoinedContestsPath(groupId);

  try {
    const response = await getRequest(path, { skipIntegrationApi: true });

    return response;
  } catch (error) {
    console.error(`Error fetching contests`, error);
    return rejectWithValue("Failed to fetch contests");
  }
});

const slice = createSlice({
  name: "contests",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchContests.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchContests.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.contests = action.payload;
      })
      .addCase(fetchContests.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(fetchUserContests.pending, (state) => {
        state.loadingJoinedContests = true;
        state.error = null;
      })
      .addCase(fetchUserContests.fulfilled, (state, action) => {
        action.payload.forEach((joinedContest) => {
          const contestId: string = joinedContest.contest_id;
          const entryIds: string[] = joinedContest.entries.map((entry: ContestEntry) => entry.id);

          entryIds.forEach((entryId: string) => {
            addToRecords(state, entryId, contestId);
          });
        });

        state.loading = false;
        state.error = null;
        state.joinedContests = action.payload;
      })
      .addCase(fetchUserContests.rejected, (state, action) => {
        state.loadingJoinedContests = false;
        state.error = action.payload as string;
      });
  },
});

export const addToRecords = (state: ContestsState, entryId: string, contestId: string) => {
  state.entryIdToContestId[entryId] = contestId;

  if (!state.contestIdToEntryId[contestId]) {
    state.contestIdToEntryId[contestId] = [];
  }

  state.contestIdToEntryId[contestId].push(entryId)
}

export const getContestsState = (state: RootState) => {
  return state.contests;
};

export const isJoined = (state: RootState, contestId: string) => {
  const joinedContestIds = getContestsState(state).joinedContests.map(
    (c) => c.contest_id
  );
  return joinedContestIds.includes(contestId);
};

export const validateContestEntryId = (state: RootState, entryId: string) => {
  return getContestsState(state).entryIdToContestId[entryId] !== undefined;
}

export const getLatestContestEntryId = (state: RootState) => {
  let latestEntryId: string | undefined = undefined;
  let latestTimestamp: string = '';

  const joinedContests: JoinedContest[] = state.contests.joinedContests;
  const allEntries: ContestEntry[] = joinedContests.flatMap(contest => contest.entries);

  allEntries.forEach((entry: ContestEntry) => {
    if (!latestEntryId || new Date(entry.created_at) > new Date(latestTimestamp)) {
      latestEntryId = entry.id;
      latestTimestamp = entry.created_at;
    }
  });

  return latestEntryId;
}

export const getContestIdByEntryId = (state: RootState, entryId: string) => {
  return getContestsState(state).entryIdToContestId[entryId];
}


export const getContestById = (state: RootState, contestId: string) => {
  return getContestsState(state).contests.find((contest) => contest.id === contestId);
}

export default slice.reducer;
