const decodeBase64 = <T>(encodedData: string | T): T => {
  if (typeof encodedData === "string") {
    try {
      const decodedData = atob(encodedData);
      return JSON.parse(decodedData) as T;
    } catch (error) {
      console.error("Error decoding base64:", encodedData, error);
      return encodedData as T;
    }
  }
  return encodedData;
};

export default decodeBase64;