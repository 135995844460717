import BlockyHeavyText from "components/custom-texts/blocky-heavy-text";
import { Entry } from "interfaces/entry";
import styles from "assets/shared-stylesheets/sparket-list-item.module.css";
import { CheckCircle } from "react-bootstrap-icons";
import entryItemStyles from "../entry-item-styles.module.css";

interface SettledEntryItemProps {
  item: Entry;
  winner: boolean;
  projectedWin: number;
}

const SingleSelectionSettledEntryItem = ({
  item,
  winner,
  projectedWin,
}: SettledEntryItemProps) => {
  const style = winner
    ? { border: "3px solid #1ba628", backgroundColor: "lightgray" }
    : { backgroundColor: "lightgray" };
  return (
    <>
      <div className={styles.itemContainer} style={style}>
        <div className={styles.leftColumnContainer}>
          <div className={`${styles.inline} ${entryItemStyles.titleText}`}>
            {winner && (
              <CheckCircle
                size={12}
                style={{ color: "green", marginRight: 4 }}
              />
            )}
            <BlockyHeavyText>{`${item.contestant_name}`}</BlockyHeavyText>
          </div>
          <div className={styles.inline}>
            <BlockyHeavyText
              className={`${styles.activeBetsText} ${entryItemStyles.labelText}`}
            >
              Projected Win:&nbsp;
            </BlockyHeavyText>
            <BlockyHeavyText className={entryItemStyles.oddsText}>
              {(projectedWin * 100).toFixed(1)}%
            </BlockyHeavyText>
          </div>
        </div>
        <div className={styles.middleColumnContainer}>
          <div className={styles.inline}>
            <BlockyHeavyText className={entryItemStyles.labelText}>
              Odds:&nbsp;
            </BlockyHeavyText>
            <BlockyHeavyText
              className={entryItemStyles.oddsText}
            >{`${item.odds.toFixed(2)}x`}</BlockyHeavyText>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleSelectionSettledEntryItem;
