import mixpanel from "mixpanel-browser";

mixpanel.init("dca97114526b628ffcd7aac1eab9d822");

const env_check = process.env.REACT_APP_ENVIRONMENT === "prod";

const actions = {
  identify: (id: string) => {
    if (env_check) mixpanel.identify(id);
  },
  alias: (id: string) => {
    if (env_check) mixpanel.alias(id);
  },
  track: (name: string, props: object) => {
    if (env_check) mixpanel.track(name, props);
  },
  people: {
    set: (props: object) => {
      if (env_check) mixpanel.people.set(props);
    },
  },
};

export const Mixpanel = actions;
