import { Auth } from "aws-amplify";
import BlockyHeavyText from "components/custom-texts/blocky-heavy-text";
import { useAppSelector } from "state/hooks";
import { getGroupState } from "state/slices/group-slice";
import { Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import { getPrizeoutSettingsPath, getUsersPersonalInformationPath, } from "utils/backend-path-builders";
import { getRequest } from "utils/httpClient";
import { User } from "components/profile/profileInfo";
import { Mixpanel } from "hooks/mixPanel";
import PrizeoutSettings from "interfaces/prizeout-settings";

interface Props {
  balance: number | undefined;
  refreshTransactionData: () => void;
}

const PrizeoutButton = ({ balance, refreshTransactionData }: Props) => {
  const groupId = useAppSelector(getGroupState).id;
  const [prizeoutSettings, setPrizeoutSettings] = useState<
    PrizeoutSettings | undefined
  >();

  useEffect(() => {
    if (!groupId) return;

    getRequest(getPrizeoutSettingsPath(groupId), { skipIntegrationApi: true }).then(
      (data: PrizeoutSettings) => {
        setPrizeoutSettings(data);
      }
    );
  }, [groupId]);

  const [user, setUser] = useState<User>();

  useEffect(() => {
    if (!prizeoutSettings) return;

    getRequest(getUsersPersonalInformationPath()).then((data) => {
      setUser(data);
    });
  }, [prizeoutSettings]);

  const launchPrizeout = async () => {
    if (!user || !prizeoutSettings) {
      return;
    }
    (window as any).prizeoutSDK.init({
      env: process.env.REACT_APP_ENVIRONMENT === "prod" ? "prod" : "sandbox",
      user: {
        firstname: user.first_name,
        lastname: user.last_name,
        year_of_birth: user.date_of_birth.split("-")[0] || 1900,
        gender: user.gender,
        email: user.email,
        user_id: user.id,
        session_id: (await Auth.currentSession()).getIdToken().getJwtToken(),
        country: user.country.toUpperCase() || "US", // this is hardcode
        region: " ",
        balance: balance === undefined ? 0 : (balance/ prizeoutSettings.ratio) * 100,
      },
      publisher: {
        name: prizeoutSettings.partner_name,
        id: prizeoutSettings.partner_id,
        apikey: prizeoutSettings.api_key,
      },
      callbacks: {
        onInit: () => {
          Mixpanel.track("Prizeout opened", { user });
        },
        onClose: refreshTransactionData,
      },
    });
  };

  if (!prizeoutSettings?.partner_id) return <></>;

  return (
    <Button onClick={launchPrizeout} size="sm">
      <BlockyHeavyText>Withdraw</BlockyHeavyText>
    </Button>
  );
};

export default PrizeoutButton;
