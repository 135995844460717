/**
 * @fileoverview EditAvatarDisplay component handles the layout of the avatar modal.
 */
import styles from "./styles.module.css";
import ProfileHeader from "./profile-section/profile-header";
import ProfileSection from "./profile-section/profile-section";
import SelectionHeader from "./selection-section/selection-header";
import SelectionSection from "./selection-section/selection-section";
import BadgesSection from "./badges-section/badges-section";
import { Spinner } from "react-bootstrap";
import React, { CSSProperties, useEffect, useState } from "react";
import { useAppSelector } from "state/hooks";
import SaveAvatarButton from "./button-section/save-avatar-button";
import { getAvatarModalState } from "state/slices/avatar-modal-slice";
import BadgesHeader from "./badges-section/badges-header";

const BADGE_GRID_DIMENSIONS = { ROWS: 4, COLUMNS: 3 };

interface ColumnProps extends CSSProperties {
  "--avatar-col-width": string;
  "--badge-col-width": string;
  "--image-row-height": string;
}

/**
 * Calculates the size in pixels of the badge square
 * @param componentWidth width of the component
 * @param margin size of margin between badges & between badges and avatar
 * @param gridColumns number of columns in the badge grid
 * @param gridRows number of rows in the badge grid
 * @returns size of the badge square in pixels
 */
const calcBadgeSquareSide = (
  componentWidth: number,
  margin: number,
  gridColumns: number,
  gridRows: number
): number => {

  const hw: number = gridColumns + gridRows;
  return (componentWidth - hw * margin) / hw;
};

/**
 * Calculates the height of the row displaying avatar and badges
 * @param gridRows number of rows in the badge grid
 * @param margin size of margin between badges
 * @param badgeSquareSide size of badge squares
 * @return height of section in pixels
 */
const calcHeight = (
  gridRows: number,
  margin: number,
  badgeSquareSide: number
): number => {

  return gridRows * (badgeSquareSide + margin);
};

/**
 * Calculates the size of the rem unit in pixels
 */
const getRem = (remElement: HTMLDivElement): number => {
  remElement.style.width = "1rem";
  remElement.style.position = "absolute";
  remElement.style.visibility = "hidden";
  document.body.appendChild(remElement);
  const remInPixels = remElement.getBoundingClientRect().width;
  document.body.removeChild(remElement);
  return remInPixels;
};

interface Props {
  containerRef: React.RefObject<HTMLDivElement>;
}

const EditAvatarDisplay: React.FC<Props> = ({ containerRef }) => {
  const avatarModalState = useAppSelector(getAvatarModalState);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const rem = getRem(document.createElement("div"));

  useEffect(() => {
    const updateDimensions = () => {
      if (containerRef.current) {
        const rect = containerRef.current.getBoundingClientRect();
        setDimensions({ width: rect.width - 2 * rem, height: rect.height - 2 * rem });
      }
    };

    updateDimensions();

    window.addEventListener("resize", updateDimensions);

    return () => {
      window.removeEventListener("resize", updateDimensions);
    };

  }, [rem, containerRef]);

  // height and width calculations
  const cols = BADGE_GRID_DIMENSIONS.COLUMNS;
  const rows = BADGE_GRID_DIMENSIONS.ROWS;

  const badgeSize: number = calcBadgeSquareSide(dimensions.width, rem, cols, rows);
  const avatarRowSide: number = calcHeight(rows, rem, badgeSize);

  const avatarColWidth: number = avatarRowSide - .5 * rem;
  const badgeColWidth: number = dimensions.width - avatarRowSide - .5 * rem;

  if (avatarModalState.loading) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          height: 90
        }}
      >
        <Spinner animation="border" variant="warning" />
      </div>
    );
  }


  return (
    <div
      className={styles.layout}
      style={{
        "--avatar-col-width": `${avatarColWidth}px`,
        "--badge-col-width": `${badgeColWidth}px`,
        "--image-row-height": `${avatarRowSide}px`,
      } as ColumnProps}>
      <div className={styles.profileHeader}>
        <ProfileHeader />
      </div>
      <div className={styles.selectionHeader}>
        <SelectionHeader />
      </div>
      <div className={styles.profileSection}>
        <ProfileSection />
      </div>
      <div className={styles.selectionSection}>
        <SelectionSection />
      </div>
      <div className={styles.achievementHeader}>
        <BadgesHeader />
      </div>
      <div className={styles.achievementSection}>
        <BadgesSection />
      </div>
      <div className={styles.buttonSection}>
        <SaveAvatarButton />
      </div>
    </div>
  );
};

export default EditAvatarDisplay;

