import React from "react";
import { Modal } from "react-bootstrap";
import { X } from "react-bootstrap-icons";
import styles from "components/avatar/modal/styles.module.css"; // Assuming custom styles are here
import { Order } from "interfaces/commerce/order/order";
import OrdersModalOrderItem from "components/profile/orders/orders-modal-order-item";
import BlockyHeavyText from "components/custom-texts/blocky-heavy-text"; // Import the OrderItem component

interface OrdersModalProps {
  show: boolean;
  onHide: () => void;
  orders: Order[];
}

const OrdersModal: React.FC<OrdersModalProps> = ({ show, onHide, orders }) => {
  return (
    <Modal show={show} onHide={onHide} dialogClassName={styles.modal}>
      <Modal.Header className={styles.modalHeader}
                    style={{ display: "flex", justifyContent: "center", position: "relative" }}>
        <div style={{ textAlign: "center" }}>
          <Modal.Title className={styles.modalTitle}>
            <BlockyHeavyText>My Orders</BlockyHeavyText>
          </Modal.Title>
        </div>
        <div className={"bold"}
             style={{ position: "absolute", right: "16px", top: "50%", transform: "translateY(-50%)", zIndex: 1 }}>
          <X size={40} style={{ cursor: "pointer", color: "white" }} onClick={onHide} />
        </div>
      </Modal.Header>

      <Modal.Body className={styles.modalBody} style={{ paddingLeft: 0, paddingRight: 0 }}>
        {orders.length > 0 ? (
          <div
            style={{
              padding: "0",
              margin: "0",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {orders.map((order) => (
              <OrdersModalOrderItem key={order.id} order={order} />
            ))}
          </div>
        ) : (
          <div className={"text-center"} style={{ color: "white" }}>No orders</div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default OrdersModal;
