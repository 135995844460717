import { useCallback, useEffect, useState } from "react";
import { CheckRequestData, CheckRequestStatus } from "@xpointtech/xpoint-js";
import { SKIP, XPOINT_NOT_FOUND } from "utils/constants";
import { useAppSelector } from "state/hooks";
import { getGroupState } from "state/slices/group-slice";

const useGeolocation = () => {
  const service = (window as any).xpjs;
  const geolocationEnabledForGroup = useAppSelector(getGroupState).settings
    .enable_geolocation;
  const [geolocationStatus, setGeolocationStatus] = useState<CheckRequestStatus>(CheckRequestStatus.WAITING);

  const removeEventListener = useCallback(() => {
    window.removeEventListener("xpoint-sdk-message", handleStatusChange);
  }, []);

  useEffect(() => {
    window.addEventListener("xpoint-sdk-message", handleStatusChange);
    return () => {
      removeEventListener();
    };
  }, [removeEventListener]);

  const handleStatusChange = (event: any) => {
    if (!event.detail.status) {
      setGeolocationStatus(XPOINT_NOT_FOUND);
    } else {
      const checkRequest: CheckRequestData = event.detail;
      if (checkRequest.status) {
        setGeolocationStatus(checkRequest.status);
      }
    }
  };

  useEffect(() => {
    if (geolocationStatus === CheckRequestStatus.ALLOWED) {
      removeEventListener();
    }
  }, [removeEventListener, geolocationStatus]);

  const checkStatus = useCallback(async () => {
    if (!geolocationEnabledForGroup) {
      return { status: SKIP };
    }

    let statusAndErrors;
    try {
      const result = await service.checkStatus();
      statusAndErrors = result;
      setGeolocationStatus(result.status);
    } catch (e) {
      statusAndErrors = {
        status: XPOINT_NOT_FOUND,
        errors: [
          {
            description:
              "Xpoint not found. Please make sure you are running the xpoint app and try again.",
          },
        ],
      };
      setGeolocationStatus(XPOINT_NOT_FOUND);
    }
    return statusAndErrors;
  }, [geolocationEnabledForGroup, service]);

  useEffect(() => {
    if (geolocationEnabledForGroup && service) {
      checkStatus();
    } else if (!service) {
      setGeolocationStatus(XPOINT_NOT_FOUND);
    }
  }, [checkStatus, geolocationEnabledForGroup, service]);

  return {
    geolocationStatus: geolocationEnabledForGroup ? geolocationStatus : SKIP,
    checkStatus,
  };
};

export default useGeolocation;
