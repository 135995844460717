import * as storage from "utils/local-storage";
import { GROUP_SLUG, TOKEN, USER_ID } from "./constants";
import { getPrefixPath } from "./backend-path-builders";

const headers = () => {
  return {
    "x-user-id": storage.local.getItem(USER_ID) || "",
    "x-login-token": storage.local.getItem(TOKEN) || "",
    "x-client-id": storage.local.getItem(GROUP_SLUG) || "",
  };
};

const groupSlug = () => {
  return "/" + storage.local.getItem(GROUP_SLUG);
};

export const integratedGet = (path: string, options: any) => {
  const url = process.env.REACT_APP_BACKEND_URL + getPrefixPath() + groupSlug() + path;

  return fetch(url, {
    method: "GET",
    headers: headers(),
  }).then((res) => res.json());
};

export const integratedPost = (path: string, options: any) => {
  const url = process.env.REACT_APP_BACKEND_URL + getPrefixPath() + groupSlug() + path;

  return fetch(url, {
    method: "POST",
    headers: headers(),
    body: JSON.stringify(options?.body),
  }).then((res) => res.json());

};

export const integratedPut = (path: string, options: { body?: any }) => {
  const url = process.env.REACT_APP_BACKEND_URL + getPrefixPath() + groupSlug() + path;

  return fetch(url, {
    method: "PUT",
    headers: headers(),
    body: JSON.stringify(options?.body),
  }).then((res) => res.json());
};
