/**
 * @fileoverview ProfileSection component displays the user's avatar in the modal.
 */
import { useAppSelector } from "state/hooks";
import { getAvatarModalState } from "state/slices/avatar-modal-slice";
import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import { Image } from "react-bootstrap";


const ProfileSection: React.FC = () => {
  const avatarModalState = useAppSelector(getAvatarModalState);
  const [currentAvatar, setCurrentAvatar] = useState<string>(avatarModalState.selectedAvatar);

  useEffect(() => {
    setCurrentAvatar(avatarModalState.selectedAvatar);
  }, [avatarModalState.selectedAvatar]);

  return (
      <Image
        src={currentAvatar}
        className={styles.avatar}
      />
  );
}

export default ProfileSection;