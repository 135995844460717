import BlockyHeavyText from "components/custom-texts/blocky-heavy-text";
import { Button, Modal } from "react-bootstrap";
import { secondaryColor, textColor } from "utils/constants";
import sharedModalStyles from "assets/shared-stylesheets/modal.module.css";
import { useAuth } from "hooks/auth";
import { useEffect } from "react";
import { Mixpanel } from "hooks/mixPanel";
import { useAppDispatch, useAppSelector } from "state/hooks";
import { local as localStorage } from "utils/local-storage";
import { getGroupState } from "state/slices/group-slice";
import { closeContestPopup, getContestModalState, openContestPopup, } from "state/slices/contest-popup-modal-slice";
import { getExternalIdModalState } from "state/slices/external-id-modal-slice";
import { X } from "react-bootstrap-icons";
import { UserGroupMembershipStatus } from "utils/userGroupMembershipStatus";
import { createPassUserLevelForGroup } from "utils/backend-path-builders";
import { getRequest } from "utils/httpClient";

// conditions where this shows up:
// 1. Haven't seen it yet
// 2. Have approved group status
// 3. Are not viewing external id modal

export const SEEN_CONTEST_POPUP_URLS = "seen-contest-popup-urls";

// contest popup
const ContestPopup = () => {
  const auth = useAuth();
  const { id, settings, status, name } = useAppSelector(getGroupState);
  const groupColor = settings.primary_color;
  const alreadyJoinedGroup = status === UserGroupMembershipStatus.APPROVED;
  const { enable_contest_popup, contest_popup_url } = settings;
  const { open } = useAppSelector(getContestModalState);
  const externalIdModalOpen = useAppSelector(getExternalIdModalState).open;
  const dispatch = useAppDispatch();

  const haventSeenYet =
    contest_popup_url &&
    !localStorage.getItem(SEEN_CONTEST_POPUP_URLS)?.includes(contest_popup_url);

  useEffect(() => {
    if (
      enable_contest_popup &&
      haventSeenYet &&
      !externalIdModalOpen &&
      alreadyJoinedGroup
    ) {
      dispatch(openContestPopup());
      const path = createPassUserLevelForGroup(id);
      getRequest(path).catch(e => console.error("failed to create pass user level", e));
    }
  }, [dispatch, haventSeenYet, id, alreadyJoinedGroup, enable_contest_popup, externalIdModalOpen, status]);
  return (
    <Modal
      show={open}
      onHide={() => dispatch(closeContestPopup(contest_popup_url))}
    >
      <Modal.Title
        style={{
          justifyContent: "space-between",
          alignItems: "center",
          background: groupColor,
        }}
        className={sharedModalStyles.modalTitle}
      >
        <div />
        <BlockyHeavyText style={{ color: textColor }}>Welcome to {name}</BlockyHeavyText>
        <X
          size={40}
          style={{ cursor: "pointer" }}
          onClick={() => dispatch(closeContestPopup(contest_popup_url))}
        />
      </Modal.Title>
      <Modal.Body
        style={{ borderColor: groupColor }}
        className={sharedModalStyles.modalBody}
      >
        <img
          src={contest_popup_url}
          alt="First slide"
          style={{ maxWidth: "100%" }}
        />
        <Button
          style={{
            backgroundColor: secondaryColor,
            border: "none",
            marginTop: 20,
          }}
          onClick={() => {
            dispatch(closeContestPopup(contest_popup_url));
            Mixpanel.track("Accepted Contest", {
              isSignedIn: auth.signedIn,
            });
          }}
        >
          <BlockyHeavyText style={{ color: "black" }}>LET'S GO</BlockyHeavyText>
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default ContestPopup;
