import XpointService from "geolocation/xpoint-service";

export const initialize = async (userId: string) => {
  const windowObj = window as any;
  try {
    if (!windowObj.xpjs) {
      windowObj.xpjs = await new XpointService();
    }
    await windowObj.xpjs.setUserId(userId);
    await windowObj.xpjs.startGame();
  } catch {}
};

export default initialize;
